
import React, { useState, useEffect } from 'react'
import './LetsConnect.css'
import ReCAPTCHA from 'react-google-recaptcha';
import "../../pages/Contact/Contact.css";
import { useDispatch } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { userInfoSubmit } from "../../../BiencapsSlice/ContactSlice";
import { db } from "../../../firebase/FirebaseContactData";
import { setDoc, doc, getDocs, collection } from "firebase/firestore";
import {FaTimesCircle} from 'react-icons/fa'
function LetsConnect() {

    const dispatch = useDispatch();

    const [User_name, setUserName] = useState("");
    const [User_number, setUserNumber] = useState("");
    const [User_Email, setUserEmail] = useState("");
    const [User_comment, setUserComment] = useState("");
    const [User_id] = useState(nanoid());

    const onUserName = (e) => {
        var onlyCharacter = /^[A-Za-z ]+$/;
        let variable = e.target.value 
        if (onlyCharacter.test(variable)){
            document.getElementById('userName').style.borderBottomColor="green"   
            document.getElementById('err_username').style.display="none"
            setUserName(variable);
        }else{
            document.getElementById('userName').style.borderBottomColor="red"
            document.getElementById('err_username').style.display="flex"
            if(variable===''){
                document.getElementById('err_username').style.display="none"
                document.getElementById('userName').style.borderBottomColor="#919B9E"  
            setUserName(variable);
            }
        }
    }


    const onUserNumber = (e) => { 

        if(e.target.value && e.target.value.length<=10){
            document.getElementById('err_number').style.display= e.target.value.length===10 ? "none" : "flex"
            document.getElementById('userNumber').style.borderBottomColor= e.target.value.length===10 ? "Green" : "red"
            setUserNumber(e.target.value)
        }else if(!e.target.value){
            document.getElementById('err_number').style.display="none"
            document.getElementById('userNumber').style.borderBottomColor="#919B9E"
            setUserNumber(e.target.value)
        }else{
            document.getElementById('err_number').style.display="none"
            document.getElementById('userNumber').style.borderBottomColor="Green"
        }
    }
    const onUserEmail = (e) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        let lowerCaseEmail = (e.target.value).toLowerCase()
        setUserEmail(lowerCaseEmail)
        if(lowerCaseEmail && !emailPattern.test(lowerCaseEmail)){
            document.getElementById('err_email').style.display="flex"
            document.getElementById('userEmail').style.borderBottomColor="red"
        }else if(!lowerCaseEmail){
            document.getElementById('err_email').style.display="none"
            document.getElementById('userEmail').style.borderBottomColor="#919B9E"
            setUserEmail(lowerCaseEmail)
        }else{
            setUserEmail(lowerCaseEmail)
            document.getElementById('err_email').style.display="none"
            document.getElementById('userEmail').style.borderBottomColor="green"
        }
    }
    const onUserComment = (e) => setUserComment(e.target.value);

    const [captchaValue, setCaptchaValue] = useState(null);

    useEffect(function () {
        async function hello() {
            const querySnapshot = await getDocs(collection(db, "Users"))
            querySnapshot.forEach((doc) => {
              
            })
        }
        hello();
    }, []);


    const handleCaptchaChange = (value) => {
        // When the user solves the CAPTCHA, 'value' will contain the CAPTCHA response.
        setCaptchaValue(value);
    };

    function handleSubmit(event) {
        event.preventDefault();
        // Check if the CAPTCHA was solved before submitting the form
        if (captchaValue) {
            if (User_name && User_number && User_Email && User_comment) {
                dispatch(
                    userInfoSubmit({
                        User_id,
                        User_name,
                        User_number,
                        User_Email,
                        User_comment,
                    })
                );

            }
            const data = {
                User_id,
                User_name,
                User_number,
                User_Email,
                User_comment,
            };

            setDoc(doc(db, "Users", "month", "data", User_id), data);
            alert("Submited Sucessfully")

            setUserName("");
            setUserNumber("");
            setUserEmail("");
            setUserComment("");
            document.getElementById('userNumber').style.borderBottomColor="#919B9E"
            document.getElementById('userName').style.borderBottomColor="#919B9E"  
            document.getElementById('userEmail').style.borderBottomColor="#919B9E"
        }
        else {
            alert('Please complete the CAPTCHA before submitting.');
            return
        }

    };

    const hiddenLetConnect = () => {

        document.getElementById('blur-div').style.visibility="hidden"
        document.getElementById('app').style.overflow="auto"
    }

  return (
   
    <div className='blur-div' id="blur-div">
        <div className='letsConnect-box' id='letsConnect-box'>
        <div className='lets-connect-heading'>
            <h1>Fill Out the form and we will be in connect as soon as possible!</h1>
            <FaTimesCircle className='lets-connect-cross-icon' onClick={() => hiddenLetConnect()}/>
        </div>
     
        <div className='lets-connect-content'>

            <div className='form lets-connect-form'>
              
                            <form onSubmit={(event) => handleSubmit(event)} id="lets-connect-form-tag">
                            <input
                                    type="text"
                                    placeholder="Enter name"
                                    onChange={onUserName}
                                    value={User_name}
                                    id="userName" maxLength={20}
                                    required
                                />
                                <span id="err_username">Enter valid charatcer</span>
                                <input
                                    type="number"
                                    placeholder="Phone number"
                                    onChange={onUserNumber}
                                    value={User_number}
                                    maxLength={10} 
                                    id="userNumber"
                                    required
                                />
                                <span id="err_number">length is less than 10</span>
                                <input
                                    type="mail"
                                    placeholder="Email id"
                                    onChange={onUserEmail}
                                    value={User_Email}
                                    id="userEmail"
                                    maxLength={30}
                                    required
                                />
                                <span id="err_email">Invalid email</span>
                                <label id='label' className='letsConnect-label' >How can we help you ?</label>
                                <textarea
                                    rows={7}
                                    onChange={onUserComment}
                                    value={User_comment}
                                    id="lets-Connect-textarea"
        
                                />
                                <div className='captcha-div'>
                                    <ReCAPTCHA id="captcha" size='' className='letsConnectCaptcha'
                                        sitekey="6Le1vicpAAAAAHZyeCeSuYh3QpPMWi1dwCp6AfLv"
                                        onChange={handleCaptchaChange}
                                        
                                    />
                                </div>
                                <button onClick={(event) => handleSubmit(event)} className="submit-button" id="letsConnetcSubmit">Send</button>
                            </form>
            </div>
            <div className='lets-connects-image'>
                <img src="/images/biencaps-logo.png" alt='/'  id='biencaps-logo'/>
                <img src="/images/biencaps-name-logo.png" alt='/' />
            </div>
        </div>
    </div>
    </div>
  )
}

export default LetsConnect