import React, { useEffect } from "react";
import "../Service/MobileApplication.css";

function MobileApplication() {
  useEffect(() => {
    document.getElementById("logoImg").src = "/images/logoWhite.png";
    document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
    document.getElementById("menu-toggle-container").style.display = "none";
    document.querySelector(".mobile-nav-toggle").classList.add("active");
    document.querySelector(".item-list").classList.add("active");
    document.getElementById("container-list0").style.color = "white";
    document.getElementById("container-list1").style.color = "white";
    document.getElementById("container-list2").style.color = "white";
    document.getElementById("container-list3").style.color = "white";
    document.getElementById("navbar").style.color = "white";
  }, [])

  return (
    <div className="Mobile-application-main-container" id="mobile-applications" style={{ height: "100vh", width: "100%", overflow: "auto" }}>
      <div className="Mobile-application-firstpage-page">
        <img src="/images/Vectorcolor.png" alt="/" id="vector-color" />
        <img src="/images/Vectorempty.png" alt="/" id="Vectorempty" />
        <img src="/images/Vectorempty.png" alt="/" id="Vectoremptyrotate" />
        <img src="/images/white-Vector.png" alt="/" id="white-Vector" />
        <img src="/images/VectorsmallWhite.png" alt="/" id="VectorsmallWhite" />
        <img src="/images/threeDot.png" alt="/" id="service-threeDot" />

        <div className="Mobile-application-first-page-content-container">
          <div className="left-container-Mobile-image">
            <img src="/images/mobile-application-image.png" alt="" id="mobile-application-image" />
          </div>

          <div className="right-container-Mobile-application">
            <div className="right-container-Mobile-application-heading">
              Mobile Application
            </div>
            <div className="right-container-Mobile-application-para">
              <p>
                Biencaps experience & intricate knowledge of user interaction
                with the app helps in planning and executing the high-tech
                cross-platform mobile app development. Our primary mobile
                application development services come with perks of creative
                inputs, the guidance of market trends, and the creation of apps
                that are worthy of your time and money, apps that work after
                real download.
              </p>
            </div>
            <div className="cards">
              <div className="card-content">
                <h1>+9</h1>
                <h3>OUR CREATION</h3>
              </div>
              <div className="card-content">
                <h1 id="card-content-h1">+19</h1>
                <h3>OUR CONNECTION</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileApplication;
