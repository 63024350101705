import React, { useEffect } from 'react'
import "./stylePageNotFound.css";
function PageNotFound() {
  useEffect(() => {
    document.getElementById("navbar").style.color = "white";
    document.getElementById("logoImg").src = "/images/logoWhite.png";
    document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
    document.getElementById("menu-toggle-container").style.display = "none";
    document.querySelector(".mobile-nav-toggle").classList.add("active");
    document.querySelector(".item-list").classList.add("active");
    document.getElementById("container-list0").style.color = "white";
    document.getElementById("container-list1").style.color = "white";
    document.getElementById("container-list2").style.color = "white";
    document.getElementById("container-list3").style.color = "white";
  }, [])
  return (
    <div className='page-not-found'>
      <div className='page-not-found-container'>
        <div className='pnp-containt-container'>
          <h1>Page Not Found</h1>
          <button onClick={() => window.history.back()} >Go Back</button>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
