import React, { useEffect } from "react";

function E_Learning() {
  useEffect(() => {
    document.getElementById("navbar").style.color = "black";
    document.getElementById("logoImg").src = "/images/logo.png";
    document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
    document.getElementById("menu-toggle-container").style.display = "none";
    document.querySelector(".mobile-nav-toggle").classList.add("active");
    document.querySelector(".item-list").classList.add("active");
    document.getElementById("container-list0").style.color = "black";
    document.getElementById("container-list1").style.color = "black";
    document.getElementById("container-list2").style.color = "black";
    document.getElementById("container-list3").style.color = "black";
  })
  const handleScroll = () => {
    const ele = document.getElementById("e-learning-page");
    if (ele.scrollTop < ele.clientHeight) {
      document.getElementById("menu-toggle-container").style.display = "none";
      document.querySelector(".mobile-nav-toggle").classList.add("active");
      document.querySelector(".item-list").classList.add("active");

    } else {

      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");

    }
  }
  return (

    <div className="E_learning-main-container-content" id="e-learning-page" style={{ height: "100vh", width: "100%", overflow: "auto" }} onScroll={handleScroll}>
      <div className="E_learning-sub-container-content">
        <div className="E_learning_div-1">
          <svg
            width="32"
            height="45"
            viewBox="0 0 32 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg-1">
            <path
              d="M22.2598 0L0.000495016 22.0477L21.8799 44.1371C34.1744 31.9595 34.3444 12.2006 22.2598 0Z"
              fill="#FF8402"
            />
          </svg>

          <svg
            width="28"
            height="34"
            viewBox="0 0 28 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg-2"
          >
            <path
              d="M21.835 33.1128L27.953 6.07136L1.11796 4.72816e-05C-2.26119 14.9358 7.01322 29.7594 21.835 33.1128Z"
              fill="#E9E5D9"
            />
          </svg>

          <svg
            width="23"
            height="31"
            viewBox="0 0 23 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg-3"
          >
            <path
              d="M18.1928 28.5705L2.06943 18.3813L12.3489 2.11514C20.5702 8.18464 23.0779 19.4793 18.1928 28.5705Z"
              stroke="#FF8402"
              strokeWidth="3"
            />
          </svg>

          <svg
            width="34"
            height="27"
            viewBox="0 0 34 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg-4"
          >
            <path
              d="M2.38298 24.5414L8.22154 1.8262L31.133 7.71523C27.2658 19.7382 14.8958 26.9929 2.38298 24.5414Z"
              stroke="#FF8402"
              strokeWidth="3"
            />
          </svg>

          <svg
            width="53"
            height="41"
            viewBox="0 0 53 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg-5"
          >
            <path
              d="M52.1152 37.3188L37.0332 8.20004e-05L-0.000667647 14.967C8.32955 35.5792 31.6604 45.5855 52.1152 37.3188Z"
              fill="#FF8402"
            />
          </svg>
          <div className="E_learning_content">
            <div className="E_learning_content_div">
              <h1 id="E_learning_bielearn_heading">Core2web</h1>
              <p className="E_learning_bielearn_para">
                Highly interactive cross-platform app developed by Biencaps Systems.4000+ students benefited from this app.</p>
            </div>
          </div>
          <div className="E_learning_image_container">
            <div className="E_learning_image_container_1">
              <img src="/images/port_core2web.png" id="Bielearn_background" alt='/' />
              <img src="/images/port_core2web_mobile.png" alt="" id="Bielearn_mobile" />
            </div>
          </div>
        </div>
        <div className="E_learning_div-1">
          <svg
            width="32"
            height="45"
            viewBox="0 0 32 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg-1">
            <path
              d="M22.2598 0L0.000495016 22.0477L21.8799 44.1371C34.1744 31.9595 34.3444 12.2006 22.2598 0Z"
              fill="#FF8402"
            />
          </svg>

          <svg
            width="28"
            height="34"
            viewBox="0 0 28 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg-2"
          >
            <path
              d="M21.835 33.1128L27.953 6.07136L1.11796 4.72816e-05C-2.26119 14.9358 7.01322 29.7594 21.835 33.1128Z"
              fill="#E9E5D9"
            />
          </svg>

          <svg
            width="23"
            height="31"
            viewBox="0 0 23 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg-3"
          >
            <path
              d="M18.1928 28.5705L2.06943 18.3813L12.3489 2.11514C20.5702 8.18464 23.0779 19.4793 18.1928 28.5705Z"
              stroke="#FF8402"
              strokeWidth="3"
            />
          </svg>

          <svg
            width="34"
            height="27"
            viewBox="0 0 34 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg-4"
          >
            <path
              d="M2.38298 24.5414L8.22154 1.8262L31.133 7.71523C27.2658 19.7382 14.8958 26.9929 2.38298 24.5414Z"
              stroke="#FF8402"
              strokeWidth="3"
            />
          </svg>

          <svg
            width="53"
            height="41"
            viewBox="0 0 53 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg-5"
          >
            <path
              d="M52.1152 37.3188L37.0332 8.20004e-05L-0.000667647 14.967C8.32955 35.5792 31.6604 45.5855 52.1152 37.3188Z"
              fill="#FF8402"
            />
          </svg>
          <div className="E_learning_content">
            <div className="E_learning_content_div">
              <h1 id="E_learning_bielearn_heading">Bielearn</h1>
              <p className="E_learning_bielearn_para">We offer an online platform to help your institute expand its presence through a mobile application available on both Android and iOS platforms, developed using Flutter.</p>
            </div>
          </div>
          <div className="E_learning_image_container">
            <div className="E_learning_image_container_1">
              <img src="/images/Bielearn-background.png" id="Bielearn_background" alt='/' />
              <img src="/images/Bielearn-mobile.png" alt="" id="Bielearn_mobile" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default E_Learning;
