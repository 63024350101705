import React, { useEffect, useState } from 'react';
import './styleLanding1.css';
import "./styleResLanding.css"
import TextTransition, {presets} from 'react-text-transition';


const TEXTS = ['INVENTING', 'EXPLORING', 'SHARING']

function Landing1() {
const [index, setIndex] = useState(0) 
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000, 
    );
    
    return () => clearTimeout(intervalId);
  }, []); 

  const routeLetsConnect = () => {
      document.getElementById('blur-div').style.visibility="visible"

  
  }
  return (
    <div className="landing slide" id='landing' >
      <div className='landing-container'>
        <div className='animation-container'>
          <div className='svg1'>
            <svg width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg" id="landing-page-svg1">
              <path d="M24.8797 2.67962L27.9218 27.8594L2.52619 30.9275C1.63927 17.2751 11.278 5.07347 24.8797 2.67962Z" stroke="#FF8402" strokeWidth="3" />
            </svg>
          </div>
          <div className='svg2'>
            <svg width="81" height="68" viewBox="0 0 81 68" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 2.12695L16.2506 67.236L80.8627 51.1094C71.887 15.1478 35.6871 -6.78022 0 2.12695Z" fill="#FF8402" />
            </svg>
          </div>
          <div className='svg3'>
            <svg width="30" height="41" viewBox="0 0 30 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.0625 40.1211L29.0868 17.8876L7.02312 0.000888905C-2.93224 12.2811 -1.12392 30.2417 11.0625 40.1211Z" fill="#FF8402" />
            </svg>
          </div>
          <div className='svg4'>
            <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.1321 26.4314L2.78406 27.2969L1.91111 1.73155C15.5892 2.02192 26.9168 12.6742 28.1321 26.4314Z" stroke="#FF8402" strokeWidth="3" />
            </svg>
          </div>
          <div className='svg5'>
            <svg width="62" height="74" viewBox="0 0 62 74" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.99219 73.668L61.282 58.0493L45.7825 0.204371C13.5874 8.83103 -5.56862 41.7186 2.99219 73.668Z" fill="#E9E5D9" />
            </svg>

          </div>
        </div>
        <div className="text-containt" id="inventing">      
          <h1>ALWAYS <span id='text-content-span'>
            <TextTransition direction='down' springConfig={presets.default}> &nbsp;{TEXTS[index % TEXTS.length]}</TextTransition>
          </span></h1>
          <p id='h3'>Being a custom firm, we provide our clients with a variety of products &amp; services. We create, design, and implement the client's ideas &amp; craft them into reality.</p>
          <div className='connect' id='connectBtn' onClick={() => routeLetsConnect()}><p>Let's connect.!</p></div>
          <div className='vector-arrow'>
          <svg className="tsvg7" width="82" height="39" viewBox="0 0 82 39" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5L42 33L77 5" stroke="#444647" strokeWidth="9" strokeLinecap="round"></path></svg>
          </div>
        </div>
        <div className='vector-corner1'>
          <svg width="128" height="129" viewBox="0 0 128 129" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 128.627V0.814453C70.5947 0.814453 127.813 58.0327 127.813 128.627H0Z" fill="#272D2F" />
            <path d="M0 128.626V66.5293C34.3002 66.5293 62.0963 94.3254 62.0963 128.626H0Z" fill="#F9FBFC" />
          </svg>
        </div>
        <div className='vector-corner-up'>
          <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 127.813V0H127.813C127.813 70.5793 70.5947 127.813 0 127.813Z" fill="#B3AFAF" />
          </svg>
        </div>
        <div className='vector-corner-up-side'>
          <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 127.813V0C70.5947 0 127.813 57.2182 127.813 127.813H0Z" fill="#F9FBFC" />
          </svg>
        </div>
        <div className='vector-corner-cone'>
          <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M96.9979 96.9979H0C0 43.4232 43.4348 0 96.9979 0V96.9979Z" fill="#E9E5D9" />
          </svg>
        </div>
        <div className='vector-corner-round'>
          <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.499 96.9979C21.7116 96.9979 0 75.2863 0 48.499C0 21.7116 21.7233 0 48.499 0C75.2863 0 96.998 21.7116 96.998 48.499C96.998 75.2863 75.2863 96.9979 48.499 96.9979Z" fill="#B3AFAF" />
            <path d="M48.4933 70.1525C36.5373 70.1525 26.8398 60.4551 26.8398 48.4991C26.8398 36.5432 36.5373 26.8457 48.4933 26.8457C60.4492 26.8457 70.1467 36.5432 70.1467 48.4991C70.1583 60.4551 60.4608 70.1525 48.4933 70.1525Z" fill="#FF8402" />
          </svg>
        </div>
        <div className='corner-square-vector'>
          <svg width="135" height="135" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M134.795 0H0.398438V134.397H134.795V0Z" fill="#272D2F" />
          </svg>
        </div>
        <div className='corner-faint-cone'>
          <svg width="80" height="135" viewBox="0 0 80 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M135.202 134.397H0.804688V0C75.0198 0 135.202 60.1657 135.202 134.397Z" fill="#B3AFAF" />
          </svg>
        </div>
        <div className='aside-square-vector'>
          <svg width="135" height="135" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M134.397 134.397C60.1656 134.397 0 74.2312 0 0H134.397V134.397Z" fill="#E9E5D9" />
          </svg>
        </div>
        <div className='vector-corner2'>
          <svg width="98" height="97" viewBox="0 0 98 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M97.994 0H0.996094V96.9979H97.994V0Z" fill="#272D2F" />
          </svg>
        </div>
      </div>   
    </div>
  )
}

export default Landing1
