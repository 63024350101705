import React, { useState } from 'react'
import '../Contact/Contact.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { userInfoSubmit } from "../../../BiencapsSlice/ContactSlice";
import { db } from "../../../firebase/FirebaseContactData";
import { setDoc, doc } from "firebase/firestore";
function Contact() {
    const dispatch = useDispatch();

    const [User_name, setUserName] = useState("");
    const [User_number, setUserNumber] = useState("");
    const [User_Email, setUserEmail] = useState("");
    const [User_comment, setUserComment] = useState("");
    const [User_id] = useState(nanoid());
    const onUserComment = (e) => setUserComment(e.target.value);

    const onUserName = (e) => {
        var onlyCharacter = /^[A-Za-z ]+$/;
        let variable = e.target.value
        if (onlyCharacter.test(variable)) {
            document.getElementById('userContactName').style.borderBottomColor = "green"
            document.getElementById('err_usernameContact').style.display = "none"
            setUserName(variable);
        } else {
            document.getElementById('userContactName').style.borderBottomColor = "red"
            document.getElementById('err_usernameContact').style.display = "flex"
            if (variable === '') {
                document.getElementById('err_usernameContact').style.display = "none"
                document.getElementById('userContactName').style.borderBottomColor = "#919B9E"
                setUserName(variable);
            }
        }
    }
    const onUserNumber = (e) => {

        if (e.target.value && e.target.value.length <= 10) {
            document.getElementById('err_usernumberContact').style.display = e.target.value.length === 10 ? "none" : "flex"
            document.getElementById('userContactNumber').style.borderBottomColor = e.target.value.length === 10 ? "Green" : "red"
            setUserNumber(e.target.value)
        } else if (!e.target.value) {
            document.getElementById('err_usernumberContact').style.display = "none"
            document.getElementById('userContactNumber').style.borderBottomColor = "#919B9E"
            setUserNumber(e.target.value)
        } else {
            document.getElementById('err_usernumberContact').style.display = "none"
            document.getElementById('userContactNumber').style.borderBottomColor = "Green"
        }
    }
    const onUserEmail = (e) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        let lowerCaseEmail = (e.target.value).toLowerCase()
        setUserEmail(lowerCaseEmail)
        if (lowerCaseEmail && !emailPattern.test(lowerCaseEmail)) {
            document.getElementById('err_emailContact').style.display = "flex"
            document.getElementById('userContactEmail').style.borderBottomColor = "red"
        } else if (!lowerCaseEmail) {
            document.getElementById('err_emailContact').style.display = "none"
            document.getElementById('userContactEmail').style.borderBottomColor = "#919B9E"
            setUserNumber(lowerCaseEmail)
        } else {
            setUserEmail(lowerCaseEmail)
            document.getElementById('err_emailContact').style.display = "none"
            document.getElementById('userContactEmail').style.borderBottomColor = "green"
        }
    }


    const [captchaValue, setCaptchaValue] = useState(null);
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };
    function handleSubmit(event) {
        event.preventDefault();
        if (captchaValue) {
            if (User_name && User_number && User_Email && User_comment) {
                dispatch(
                    userInfoSubmit({
                        User_id,
                        User_name,
                        User_number,
                        User_Email,
                        User_comment,
                    })
                );
                const data = {
                    User_id,
                    User_name,
                    User_number,
                    User_Email,
                    User_comment,
                };

                setDoc(doc(db, "Users", "month", "data", User_id), data)
                alert("Submited Sucessfully")
            }
            setUserName("");
            setUserNumber("");
            setUserEmail("");
            setUserComment("");
            document.getElementById('userNumber').style.borderBottomColor = "#919B9E"
            document.getElementById('userContactName').style.borderBottomColor = "#919B9E"
            document.getElementById('userEmail').style.borderBottomColor = "#919B9E"

        }
        else {
            alert('Please complete the CAPTCHA before submitting.');
            return
        }
    };
    return (
        <section className="contact-main-container slide" id='contact'>
            <div className='contact-sub-container'>
                <div className='contact-heading'>
                    <div className='contact-heading-middle'>
                        <svg width="26" height="36" viewBox="0 0 26 36" fill="none" xmlns="http://www.w3.org/2000/svg" id="contact-vector-1">
                            <path d="M21.1238 33.3042L2.19779 21.3437L14.2621 2.2533C24.0226 9.30822 26.9841 22.6498 21.1238 33.3042Z" stroke="#FF8402" strokeWidth="3" />
                        </svg>

                        <div className='contact-heading' alt="/" >CONTACT US</div>
                        <svg width="32" height="45" viewBox="0 0 32 45" fill="none" xmlns="http://www.w3.org/2000/svg" id="contact-vector-2">
                            <path d="M22.5156 0L0.256354 22.0477L22.1358 44.1371C34.4302 31.9595 34.6003 12.2006 22.5156 0Z" fill="#FF8402" />
                        </svg>

                    </div>
                </div>
                <div className='contact-middle-container'>
                    <div className='contact-left-logos'>
                        <svg width="42" height="50" viewBox="0 0 42 50" fill="none" xmlns="http://www.w3.org/2000/svg" id="contact-vector-3">
                            <path d="M32.2148 49.498L41.2782 9.43835L1.52429 0.444199C-3.48165 22.5703 10.2576 44.5303 32.2148 49.498Z" fill="#E9E5D9" />
                        </svg>

                        <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg" id="contact-vector-4">
                            <path d="M17.0273 33.7793L26.6686 9.56703L2.64115 -0.000613124C-2.68398 13.3725 3.75631 28.4948 17.0273 33.7793Z" fill="#FF8402" />
                        </svg>

                    </div>
                    <div className='contact-form'>

                        <div className='form homeContact'>
                            <form onSubmit={(event) => handleSubmit(event)}>
                                <input
                                    type="text"
                                    placeholder="Enter name"
                                    onChange={onUserName}
                                    value={User_name}
                                    id="userContactName"
                                    maxLength={20}
                                    required
                                />
                                <span id="err_usernameContact">Enter valid charatcer</span>
                                <input
                                    type="number"
                                    placeholder="Phone number"
                                    onChange={onUserNumber}
                                    value={User_number}
                                    maxLength={10}
                                    id="userContactNumber"
                                    required
                                />
                                <span id="err_usernumberContact">length is less than 10</span>
                                <input
                                    type="mail"
                                    placeholder="Email id"
                                    onChange={onUserEmail}
                                    value={User_Email}
                                    id="userContactEmail"
                                    maxLength={30}
                                    required
                                />
                                <span id="err_emailContact">Invalid email</span>

                                <label id='label'>How we can help you ?</label>
                                <textarea
                                    rows={8}
                                    onChange={onUserComment}
                                    value={User_comment}
                                />
                                <div className='captcha-div'>
                                    <ReCAPTCHA id="captcha" className='ContactCpatcha'
                                        sitekey="6Le1vicpAAAAAHZyeCeSuYh3QpPMWi1dwCp6AfLv"
                                        onChange={handleCaptchaChange}
                                    />
                                </div>
                                <button className="submit-button">Send</button>
                            </form>
                        </div>
                    </div>
                    <img src="/images/threeDot.png" alt="" id="threeDot" />
                    <img src="/images/Group.png" alt="" id="Group" />
                </div>

            </div>
        </section>
    )
}

export default Contact;
