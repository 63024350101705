import React, { useEffect } from "react";
import "../About/AboutClick.css";
import BottomContact from "../../components/BottomContact/BottomContact";

function AboutClick() {
  useEffect(() => {
    const newEle = document.getElementById('AboutClickBottomContact')
    newEle.style.background = "#F2F2F2"
    newEle.style.color = "#272D2F"
    newEle.style.fill = "#fff";
    newEle.style.boxShadow = "1px -1px 20px 0px #0000007d";
    newEle.children[1].children[1].children[0].children[2].children[1].setAttribute("style", "color:black");

    document.getElementById("linkedIn").src = "/images/darkLinked.png";
    document.getElementById("behance").src = "/images/darkBe.png";
    document.getElementById("instagram").src = "/images/darkInsta.png";
    document.getElementById("youtube").src = "/images/darkyt.png";
    document.getElementById("facebook").src = "/images/darkFacebook.png";
    document.getElementById("phone").src = "/images/darkPhone.png";
    document.getElementById("mailimg").src = "/images/darkMail.png";
    document.getElementById("adress-icon").src = "/images/darkLocation.png";
    document.getElementById("navbar").style.color = "black";
    document.getElementById("logoImg").src = "/images/logo.png";
    document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
    document.getElementById("menu-toggle-container").style.display = "none";
    document.querySelector(".mobile-nav-toggle").classList.add("active");
    document.querySelector(".item-list").classList.add("active");
    document.getElementById("container-list0").style.color = "black";
    document.getElementById("container-list1").style.color = "black";
    document.getElementById("container-list2").style.color = "black";
    document.getElementById("container-list3").style.color = "black";
  }, [])
  const handleScroll = () => {
    const ele = document.getElementById("aboutus");
    if (ele.scrollTop < ele.clientHeight) {
      document.getElementById("menu-toggle-container").style.display = "none";
      document.querySelector(".mobile-nav-toggle").classList.add("active");
      document.querySelector(".item-list").classList.add("active");

    } else {

      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");

    }
  }
  return (
    <div className="about-click-main-container" id="aboutus" style={{ height: "100vh", width: "100%", overflow: "auto" }} onScroll={handleScroll}>
      {/* First page */}
      <div className="about-click-first-page">
        <div className="first-page-info">
          <div className="first-page-heading">
            <h1>
              <span id="span-1">
                <span style={{ fontSize: "60px", color: "#FF8402" }} id="about-click-span-biencaps">
                  Biencaps
                </span>
              </span>{" "}
              A perfect place to elevate your business with exponential growth !
            </h1>
          </div>
          <div className="first-page-text">
            Biencaps is established in July 2019 with the endeavoring and
            diverse vision to break through the general norms to create and
            deliver the innovative. With an excellent team and creatives heads,
            we tend to craft the best of the best from an idea. With our
            adaptive R&Ds, we have been able to create products that capture
            solutions to real- world problems. Throughout our experience as a
            Multi-domain firm, we have gained the trust of our clients for being
            a reliable solution.
          </div>
          <svg
            width="82"
            height="39"
            viewBox="0 0 82 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="About-down-arraw1"
          >
            <path
              d="M5 5L42 33L77 5"
              stroke="#444647"
              strokeOpacity="0.6"
              strokeWidth="9"
              strokeLinecap="round"
            />
          </svg>
          <svg
            width="82"
            height="39"
            viewBox="0 0 82 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="About-down-arraw2"
          >
            <path
              d="M5 5L42 33L77 5"
              stroke="#444647"
              strokeOpacity="0.2"
              strokeWidth="9"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
      <svg
        width="27"
        height="36"
        viewBox="0 0 27 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="about-click-vector-1"
      >
        <path
          d="M21.5379 33.1596L2.61185 21.1992L14.6762 2.10877C24.4367 9.16369 27.3982 22.5053 21.5379 33.1596Z"
          stroke="#FF8402"
          strokeWidth="3"
        />
      </svg>

      <svg
        width="33"
        height="45"
        viewBox="0 0 33 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="about-click-vector-2"
      >
        <path
          d="M23.0586 0L0.799323 22.0477L22.6788 44.1371C34.9732 31.9595 35.1432 12.2006 23.0586 0Z"
          fill="#FF8402"
        />
      </svg>

      <svg
        width="41"
        height="50"
        viewBox="0 0 41 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="about-click-vector-3"
      >
        <path
          d="M31.7539 49.498L40.8172 9.43835L1.06335 0.444199C-3.94259 22.5703 9.7967 44.5303 31.7539 49.498Z"
          fill="#272D2F"
        />
      </svg>

      <svg
        width="36"
        height="49"
        viewBox="0 0 36 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="about-click-vector-4"
      >
        <path
          d="M10.7812 48.6602L35.2048 24.2366L10.9677 -0.000468658C-2.52212 13.4893 -2.60558 35.2733 10.7812 48.6602Z"
          fill="#FF8402"
        />
      </svg>

      <svg
        width="37"
        height="30"
        viewBox="0 0 37 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="about-click-vector-5"
      >
        <path
          d="M2.2198 27.9355L8.83506 2.19855L34.7916 8.87027C30.4892 22.5582 16.4144 30.812 2.2198 27.9355Z"
          stroke="#FF8402"
          strokeWidth="3"
        />
      </svg>

      <svg
        width="23"
        height="89"
        viewBox="0 0 23 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="about-click-circle-group-vector"
      >
        <path
          d="M22.4437 11.1685C22.6849 17.3353 17.8786 22.5329 11.7118 22.7741C5.5449 23.0153 0.347325 18.209 0.106117 12.0422C-0.13509 5.87531 4.6712 0.677737 10.838 0.436529C17.0047 0.189316 22.2022 4.99561 22.4437 11.1685Z"
          fill="#FF8402"
        />
        <path
          d="M22.4437 44.9907C22.6849 51.1576 17.8786 56.3551 11.7118 56.5964C5.5449 56.8376 0.347325 52.0313 0.106117 45.8644C-0.13509 39.6976 4.6712 34.5 10.838 34.2588C17.0047 34.0116 22.2022 38.8179 22.4437 44.9907Z"
          fill="#272D2F"
        />
        <path
          d="M22.4437 76.7856C22.6849 82.9525 17.8786 88.1501 11.7118 88.3913C5.5449 88.6325 0.347325 83.8262 0.106117 77.6593C-0.13509 71.4925 4.6712 66.2949 10.838 66.0537C17.0047 65.8065 22.2022 70.6128 22.4437 76.7856Z"
          fill="#E9E5D9"
        />
      </svg>

      {/* Second page */}
      <div className="about-click-second-page">
        <div className="second-page-slot-one-images">
          <div className="leader-1-div">
            <a href="https://in.linkedin.com/in/shashi-bagal-36b392b7">
              <div className="card middle">
                <img id="leaderLinkedIn" src="/images/linkedInColor1.png" alt="/" />
                <div className="front">
                  <img src="/images/leader-one.png" alt="/" />
                </div>

                <div className="back">
                  <div className="back-content middle">
                    <div className="back-content-text">
                      <p>Biencaps systems pvt. ltd.</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div className="leader-info">
              <h2 id="leader-name">SHASHIKANT BAGAL</h2>
              <h3 id="leader-post">Co-founder & CEO</h3>
              <h4 id="leader-skills">Entrepreneur</h4>
            </div>
          </div>

          <div className="leader-1-div">
            <a href="https://www.linkedin.com/in/pramod-bansode-0002b9265/">
              <div className="card middle">
                <img id="leaderLinkedIn" src="/images/linkedInColor1.png" alt="/" />
                <div className="front">
                  <img src="/images/leader-two.png" alt="/" />
                </div>

                <div className="back">
                  <div className="back-content middle">
                    <div className="back-content-text">
                      <p>Biencaps systems pvt. ltd.</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div className="leader-info">
              <h2 id="leader-name">PRAMOD BANSODE</h2>
              <h3 id="leader-post">Co-Founder & CTO</h3>
              <h4 id="leader-skills">Backend Developer</h4>
            </div>
          </div>

          {/* <div className="leader-1-div">
            <a href="https://www.linkedin.com/in/sachin-patil-5304367a/">
              <div className="card middle">
                <img id="leaderLinkedIn" src="/images/linkedInColor1.png" alt="/" />
                <div className="front">
                  <img src="/images/leader-three.png" alt="/" />
                </div>

                <div className="back">
                  <div className="back-content middle">
                    <div className="back-content-text">
                      <p>Biencaps systems pvt. ltd.</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div className="leader-info">
              <h2 id="leader-name">SACHIN PATIL</h2>
              <h3 id="leader-post">Co-Founder & COO</h3>
              <h4 id="leader-skills">Frontend Developer</h4>
            </div>
          </div> */}
        </div>
        <div className="second-page-slot-two-images">
          <h1 className="leaders-heading">Our<br />Amazing<br />Team</h1>
          <div className="leader-2-div">
            <a href="https://www.linkedin.com/in/akshay-jagtap-19aab3160/" >
              <div className="card middle">
                <img id="leaderLinkedIn" src="/images/linkedInColor1.png" alt="/" />
                <div className="front">
                  <img src="/images/leader-four.png" alt="/" />
                </div>

                <div className="back">
                  <div className="back-content middle">
                    <div className="back-content-text">
                      <p>Biencaps systems pvt. ltd.</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div className="leader-info">
              <h2 id="leader-name">AKASHAY JAGTAP</h2>
              <h3 id="leader-post">Co-Founder</h3>
              <h4 id="leader-skills">Mobile Developer</h4>
            </div>
          </div>

          {/* <div className="leader-2-div">
            <a href="https://www.linkedin.com/in/swapnil-mishra-985b921b2/">
              <div className="card middle">
                <img id="leaderLinkedIn" src="/images/linkedInColor1.png" alt="/" />
                <div className="front">
                  <img src="/images/leader-five.png" alt="/" />
                </div>

                <div className="back">
                  <div className="back-content middle">
                    <div className="back-content-text">
                      <p>Biencaps systems pvt. ltd.</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div className="leader-info">
              <h2 id="leader-name">SWAPNIL MISHRA</h2>
              <h3 id="leader-post">Co-Founder</h3>
              <h4 id="leader-skills">Backend Developer</h4>
            </div>
          </div> */}


          {/* <div className="leader-2-div">
            <a href="https://www.linkedin.com/in/sakshi-patil-257b2417b/">
              <div className="card middle">
                <img id="leaderLinkedIn" src="/images/linkedInColor1.png" alt="/" />
                <div className="front">
                  <img src="/images/leader-six.png" alt="/" />
                </div>

                <div className="back">
                  <div className="back-content middle">
                    <div className="back-content-text">
                      <p>Biencaps systems pvt. ltd.</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div className="leader-info">
              <h2 id="leader-name">SAKSHI PATIL</h2>
              <h3 id="leader-post">Team Lead</h3>
              <h4 id="leader-skills">Web Developer</h4>
            </div>
          </div> */}
          <div className="leader-2-div">
            <a href="https://www.linkedin.com/in/sachin-patil-5304367a/">
              <div className="card middle">
                <img id="leaderLinkedIn" src="/images/linkedInColor1.png" alt="/" />
                <div className="front">
                  <img src="/images/leader-three.png" alt="/" />
                </div>

                <div className="back">
                  <div className="back-content middle">
                    <div className="back-content-text">
                      <p>Biencaps systems pvt. ltd.</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div className="leader-info">
              <h2 id="leader-name">SACHIN PATIL</h2>
              <h3 id="leader-post">Co-Founder & COO</h3>
              <h4 id="leader-skills">Frontend Developer</h4>
            </div>
          </div>
        </div>
        <svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg" id="leader-page-vector-1">
          <path d="M0.0474486 50.3411C-1.08295 79.2416 21.4414 103.6 50.3419 104.73C79.2424 105.86 103.6 83.3361 104.731 54.4356C105.861 25.5352 83.3369 1.17708 54.4364 0.0466731C25.537 -1.11188 1.17895 21.4125 0.0474486 50.3411Z" fill="#E9E5D9" fill-opacity="0.5" />
        </svg>

        <svg width="37" height="30" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg" id="leader-page-vector-2">
          <path d="M34.4364 27.5624L27.8212 1.82551L1.8646 8.49723C6.16709 22.1851 20.2419 30.4389 34.4364 27.5624Z" stroke="#424546" strokeWidth="3" />
        </svg>

        <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" id="leader-page-vector-3">
          <path d="M0.0329731 25.4088C-0.537354 39.9901 10.8269 52.2796 25.4082 52.8499C39.9895 53.4202 52.279 42.0559 52.8493 27.4746C53.4197 12.8934 42.0554 0.603875 27.4741 0.0335477C12.8933 -0.550975 0.603855 10.8133 0.0329731 25.4088Z" fill="#E9E5D9" />
        </svg>

        <svg width="24" height="34" viewBox="0 0 24 34" fill="none" xmlns="http://www.w3.org/2000/svg" id="leader-page-vector-4">
          <path d="M7.32422 0.607422L23.9326 17.0579L7.60762 33.5395C-1.56563 24.4534 -1.69251 9.71065 7.32422 0.607422Z" fill="#FF8402" />
        </svg>

        <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" id="leader-page-vector-5">
          <path d="M27.3764 2.53164L27.3739 2.53154C14.176 2.00246 3.04803 12.2893 2.53106 25.5065C2.0147 38.7081 12.3043 49.8354 25.5059 50.3518C38.7076 50.8682 49.8349 40.5786 50.3512 27.3769C50.8676 14.1753 40.578 3.048 27.3764 2.53164Z" stroke="#E9E5D9" strokeWidth="5" />
        </svg>

        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" id="leader-page-vector-6">
          <path d="M-7 7.42773L21.9006 -0.000684162L29.2723 28.6793C13.3097 32.7822 -2.92839 23.2685 -7 7.42773Z" fill="#424546" />
        </svg>

        <svg width="33" height="45" viewBox="0 0 33 45" fill="none" xmlns="http://www.w3.org/2000/svg" id="leader-page-vector-7">
          <path d="M9.87891 0L32.1382 22.0477L10.2587 44.1371C-2.03569 31.9595 -2.20573 12.2006 9.87891 0Z" fill="#FF8402" />
        </svg>

        <svg width="33" height="45" viewBox="0 0 33 45" fill="none" xmlns="http://www.w3.org/2000/svg" id="leader-page-vector-8">
          <path d="M9.87891 0L32.1382 22.0477L10.2587 44.1371C-2.03569 31.9595 -2.20573 12.2006 9.87891 0Z" fill="#EAE6DB" />
        </svg>

        <svg width="41" height="50" viewBox="0 0 41 50" fill="none" xmlns="http://www.w3.org/2000/svg" id="leader-page-vector-9">
          <path d="M9.0625 49.0547L-0.000839111 8.99499L39.7531 0.000839278C44.759 22.1269 31.0197 44.087 9.0625 49.0547Z" fill="#272D2F" />
        </svg>

      </div>

      <div className="about-click-second-page-mobile">
        <div className="Mobile-blur-div">
          <div className="leader-heading">
            <h2 style={{ color: '#FF8402' }}>Meet Our Leaders</h2>
          </div>
          <div className="Leader-div-1">
            <div className="leader-info-1">
              <a href="https://in.linkedin.com/in/shashi-bagal-36b392b7"><img id="leaderLinkedInLeft" src="/images/linkedInColor1.png" alt="/" /></a>
              <img src="/images/leader-one.png" alt="/" id="leader-img-one" />
              <div className="leader-text">
                <h3 id="name" style={{ color: '#FF8402' }}>SHASHIKANT BAGAL</h3>
                <h4 id="position">Co-founder & CEO</h4>
                <h5>Enterpreneur</h5>
              </div>
            </div>
            <div className="leader-info-1">
              <a href="https://www.linkedin.com/in/pramod-bansode-0002b9265/"><img id="leaderLinkedIn" src="/images/linkedInColor1.png" alt="/" /></a>
              <img src="/images/leader-two.png" alt="/" id="leader-img-one" />
              <div className="leader-text">
                <h3 id="name" style={{ color: '#FF8402' }}>PRAMOD BANSODE</h3>
                <h4 id="position">Co-founder & CTO</h4>
                <h5>Backend Developer</h5>
              </div>
            </div>
          </div>
          <div className="Leader-div-1">
            <div className="leader-info-1">
              <a href="https://www.linkedin.com/in/sachin-patil-5304367a/"><img id="leaderLinkedInLeft" src="/images/linkedInColor1.png" alt="/" /></a>
              <img src="/images/leader-three.png" alt="/" id="leader-img-one" />
              <div className="leader-text">
                <h3 id="name" style={{ color: '#FF8402' }}>SACHIN PATIL</h3>
                <h4 id="position">Co-founder & COO</h4>
                <h5>Frontend Developer</h5>
              </div>
            </div>
            <div className="leader-info-1">
              <a href="https://www.linkedin.com/in/akshay-jagtap-19aab3160/"><img id="leaderLinkedIn" src="/images/linkedInColor1.png" alt="/" /></a>
              <img src="/images/leader-four.png" alt="/" id="leader-img-one" />
              <div className="leader-text">
                <h3 id="name" style={{ color: '#FF8402' }}>AKASHAY JAGTAP</h3>
                <h4 id="position">Co-founder</h4>
                <h5>Mobile Developer</h5>
              </div>
            </div>
          </div>
          <div className="Leader-div-1">
            <div className="leader-info-1">
              <a href="https://www.linkedin.com/in/swapnil-mishra-985b921b2/"><img id="leaderLinkedInLeft" src="/images/linkedInColor1.png" alt="/" /></a>
              <img src="/images/leader-five.png" alt="/" id="leader-img-one" />
              <div className="leader-text">
                <h3 id="name" style={{ color: '#FF8402' }}>SWAPNIL MISHRA</h3>
                <h4 id="position">Co-founder</h4>
                <h5>Backend Developer</h5>
              </div>
            </div>
            <div className="leader-info-1">
              <a href="https://www.linkedin.com/in/sakshi-patil-257b2417b/"><img id="leaderLinkedIn" src="/images/linkedInColor1.png" alt="/" /></a>
              <img src="/images/leader-six.png" alt="/" id="leader-img-one" />
              <div className="leader-text">
                <h3 id="name" style={{ color: '#FF8402' }}>SAKSHI PATIL</h3>
                <h4 id="position">Team Lead</h4>
                <h5>Web Developer</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="about-click-third-page">
        <div className="Our-Team">
          <p>Our Amazing Team</p>
        </div>
      </div> */}

      {/* Fourth page */}
      <div className="about-click-fourth-page">
        <div className="fourth-page-content">
          <div className="about-click-4-para">
            <h1 id="about-click-head-1">What makes us different?</h1>
            <p className="about-para-first">
              Being a multi-domain evolving firm, we always seek solutions. We
              believe in 'with the right strategic approach, everything is
              possible'. We offer products and services that give our clients
              the power to expand their business with consistent growth. Our
              supportive work-culture is the key to well-planned and immersed
              development processes.
            </p>
            <h1 id="about-click-head2">Our Mission</h1>
            <div className="about-para-second">
              <p>
                As an evolving company, we always thrive on the solutions and
                answers to questions before they get asked. As a supportive
                partner to our clients, we intend to offer them the competitive
                advantage that gives them the power to gain a dominant position in
                this evolving environment.
              </p>
            </div>
          </div>
        </div>
        <img
          src="/images/About-click-fourth-page-game-image.png"
          id="About-click-fourth-page-game-image"
          alt="/"
        />

        <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg" id="fourth-page-circle">
          <path d="M0.0329731 25.4088C-0.537354 39.9901 10.8269 52.2796 25.4082 52.8499C39.9895 53.4202 52.279 42.0559 52.8493 27.4746C53.4197 12.8934 42.0554 0.603875 27.4741 0.0335477C12.8933 -0.550975 0.603855 10.8133 0.0329731 25.4088Z" fill="#E9E5D9" />
        </svg>

        <svg width="24" height="89" viewBox="0 0 24 89" fill="none" xmlns="http://www.w3.org/2000/svg" id="fourth-page-three-circles">
          <path d="M0.443039 11.1685C0.201831 17.3353 5.00812 22.5329 11.175 22.7741C17.3418 23.0153 22.5394 18.209 22.7806 12.0422C23.0218 5.87531 18.2155 0.677737 12.0487 0.436529C5.88206 0.189316 0.684482 4.99561 0.443039 11.1685Z" fill="#FF8402" />
          <path d="M0.443039 44.9927C0.201831 51.1595 5.00812 56.3571 11.175 56.5983C17.3418 56.8395 22.5394 52.0332 22.7806 45.8664C23.0218 39.6995 18.2155 34.502 12.0487 34.2607C5.88206 34.0135 0.684482 38.8198 0.443039 44.9927Z" fill="#272D2F" />
          <path d="M0.435227 76.7856C0.194019 82.9525 5.00031 88.1501 11.1672 88.3913C17.334 88.6325 22.5316 83.8262 22.7728 77.6593C23.014 71.4925 18.2077 66.2949 12.0409 66.0537C5.87424 65.8065 0.676669 70.6128 0.435227 76.7856Z" fill="white" />
        </svg>

        <svg width="27" height="36" viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg" id="about-click-fourth-page-vector-2">
          <path d="M5.19647 33.1596L24.1225 21.1992L12.0582 2.10877C2.29769 9.16369 -0.663784 22.5053 5.19647 33.1596Z" stroke="#FF8402" strokeWidth="3" />
        </svg>

        <svg width="33" height="45" viewBox="0 0 33 45" fill="none" xmlns="http://www.w3.org/2000/svg" id="about-click-fourth-page-vector-1">
          <path d="M9.87891 0L32.1382 22.0477L10.2587 44.1371C-2.03569 31.9595 -2.20573 12.2006 9.87891 0Z" fill="#FF8402" />
        </svg>
      </div>
      <BottomContact />
    </div>
  );
}

export default AboutClick;
