import './App.css';
import RoutingComponent from './RoutingComponent';
import LetsConnect from './website/components/letConnect/LetsConnect';
import Navbar from './website/components/navbar/Navbar';
import AnimatedCursor from 'react-animated-cursor';
import { BrowserRouter } from 'react-router-dom';

function App() {
//   let checkInterval;

//   function checkDevTools() {
//     if (window.outerWidth - window.innerWidth > 100 || window.outerHeight - window.innerHeight > 100) {
//       // The difference in dimensions suggests that the developer tools might be open
//       window.location.href="/dfjsdkjs55fvdf4"
//       clearInterval(checkInterval);
//     }
//   }

// // Check every second
// checkInterval = setInterval(checkDevTools, 1000);
  return (
    <div className="App" id="app">
      <div className='cursor'>
        <AnimatedCursor
          innerSize={8}
          outerSize={35}
          innerScale={1}
          outerScale={1}
          outerAlpha={0}
          hasBlendMode={true}
          innerStyle={{
            backgroundColor: 'transperent',
            border: '0px solid var(--cursor-color)'
          }}
          outerStyle={{
            border: '2px solid var(--cursor-color)'
          }}
          showSystemCursor={true}
        />
      </div>
      <BrowserRouter>
        <LetsConnect />
        <Navbar />
        <RoutingComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;
