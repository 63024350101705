import React, { useEffect } from 'react';
import "./styleBielearn.css";
import BieSolutions from './BieSolutions';
import BieTestimonials from './BieTestimonials';
import BottomContact from "../../../components/BottomContact/BottomContact";
import BielearnSwiper from './BielearnSwiper';
function Bielearn() {
    useEffect(() => {
        const newEle = document.getElementById('AboutClickBottomContact')
        newEle.style.background = "#F2F2F2"
        newEle.style.color = "#272D2F"
        newEle.style.boxShadow = "1px -1px 20px 0px #0000007d";
        newEle.children[1].children[1].children[0].children[2].children[1].setAttribute("style", "color:black");
        document.getElementById("linkedIn").src = "/images/darkLinked.png";
        document.getElementById("behance").src = "/images/darkBe.png";
        document.getElementById("instagram").src = "/images/darkInsta.png";
        document.getElementById("youtube").src = "/images/darkyt.png";
        document.getElementById("facebook").src = "/images/darkFacebook.png";
        document.getElementById("phone").src = "/images/darkPhone.png";
        document.getElementById("mailimg").src = "/images/darkMail.png";
        document.getElementById("adress-icon").src = "/images/darkLocation.png";
        const ele = document.getElementById("bielearn-prod");
        if (ele.scrollTop < ele.clientHeight) {
            document.getElementById("navbar").style.color = "white";
            document.getElementById("logoImg").src = "/images/logoWhite.png";
            document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
            document.getElementById("menu-toggle-container").style.display = "none";
            document.querySelector(".mobile-nav-toggle").classList.add("active");
            document.querySelector(".item-list").classList.add("active");
            document.getElementById("container-list0").style.color = "white";
            document.getElementById("container-list1").style.color = "white";
            document.getElementById("container-list2").style.color = "white";
            document.getElementById("container-list3").style.color = "white";

        } else {
            document.getElementById("navbar").style.color = "black";
            document.getElementById("logoImg").src = "/images/logo.png";
            document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
            document.getElementById("menu-toggle-container").style.display = "flex";
            document.querySelector(".mobile-nav-toggle").classList.remove("active");
            document.querySelector(".item-list").classList.remove("active");
            document.getElementById("container-list0").style.color = "black";
            document.getElementById("container-list1").style.color = "black";
            document.getElementById("container-list2").style.color = "black";
            document.getElementById("container-list3").style.color = "black";
        }
    })
    const handleScroll = () => {
        const ele = document.getElementById("bielearn-prod");
        if (ele.scrollTop < ele.clientHeight) {
            document.getElementById("navbar").style.color = "white";
            document.getElementById("logoImg").src = "/images/logoWhite.png";
            document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
            document.getElementById("menu-toggle-container").style.display = "none";
            document.querySelector(".mobile-nav-toggle").classList.add("active");
            document.querySelector(".item-list").classList.add("active");
            document.getElementById("container-list0").style.color = "white";
            document.getElementById("container-list1").style.color = "white";
            document.getElementById("container-list2").style.color = "white";
            document.getElementById("container-list3").style.color = "white";

        } else {
            document.getElementById("navbar").style.color = "black";
            document.getElementById("logoImg").src = "/images/logo.png";
            document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
            document.getElementById("menu-toggle-container").style.display = "flex";
            document.querySelector(".mobile-nav-toggle").classList.remove("active");
            document.querySelector(".item-list").classList.remove("active");
            document.getElementById("container-list0").style.color = "black";
            document.getElementById("container-list1").style.color = "black";
            document.getElementById("container-list2").style.color = "black";
            document.getElementById("container-list3").style.color = "black";

        }
    }
    return (
        <div className='bielearn-prod' id='bielearn-prod' style={{ height: "100vh", width: "100%", overflow: "auto" }} onScroll={handleScroll}>
            <div className='bielearn-prod-container'>
                <div className='bielearnProd-text-info'>
                    <div className='bielearn-text-heading'>
                        <h1>Bielearn - Never Stop Learning !</h1>
                        <p>We offer an online platform to help your institute expand its presence through a mobile application available on both Android and iOS platforms, developed using Flutter.</p>
                    </div>
                    <div className='bielearn-btnInfo'>
                        <button>Get Started</button>
                        <p><img src="/images/playIcon.png" alt='/' /><a href="https://www.youtube.com/watch?v=TGJaMqlGCk8" target='_blank' rel="noreferrer">Watch the video</a></p>
                    </div>

                </div>
                <svg className='down-arrow' width="50" height="70" viewBox="0 0 50 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.1418 55.9337L49.4265 35.6911C50.1901 34.929 50.1913 33.6924 49.4294 32.929C48.6674 32.1654 47.4308 32.1641 46.6672 32.9261L26.381 53.1701C25.6195 53.9316 24.3804 53.9316 23.6174 53.1686L3.33267 32.9261C2.5691 32.1641 1.33247 32.1654 0.570557 32.929C0.19009 33.3101 -4.19617e-05 33.8095 -4.19617e-05 34.3086C-4.19617e-05 34.8091 0.191162 35.3096 0.573486 35.6911L20.8568 55.9322C23.1414 58.2168 26.8587 58.2168 29.1418 55.9337Z" fill="white" />
                    <path d="M29.1418 35.9337L49.4265 15.6911C50.1901 14.929 50.1913 13.6924 49.4294 12.929C48.6674 12.1654 47.4308 12.1641 46.6672 12.9261L26.381 33.1701C25.6195 33.9316 24.3804 33.9316 23.6174 33.1686L3.33267 12.9261C2.5691 12.1641 1.33247 12.1654 0.570557 12.929C0.19009 13.3101 -4.19617e-05 13.8095 -4.19617e-05 14.3086C-4.19617e-05 14.8091 0.191162 15.3096 0.573486 15.6911L20.8568 35.9322C23.1414 38.2168 26.8587 38.2168 29.1418 35.9337Z" fill="white" />
                </svg>
            </div>
            <BieSolutions />
            <BieTestimonials />
            <BielearnSwiper />
            <BottomContact />
        </div>
    )
}

export default Bielearn;
