import React, { useState } from 'react';
import "../product/styleProduct.css";
import { Zoom, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';

function Product() {
  const [heading, setHeading] = useState("Bielearn");
  const [subheading, setSubHeading] = useState("Never Stop Learning !");
  const [description, setdescription] = useState("We offer an online platform to help your institute expand its presence through a mobile application available on both Android and iOS platforms, developed using Flutter.");
  const [productLink, setProductLink] = useState("/products/bielearn");

  const handleSlideChange = (swiper) => {
    const activeDiv = document.getElementsByClassName("swiper-slide")[swiper.activeIndex].children[0].id;
    if (activeDiv === "bielearn-image") {
      setHeading("Bielearn");
      setSubHeading("Never Stop Learning !");
      setdescription("We offer an online platform to help your institute expand its presence through a mobile application available on both Android and iOS platforms, developed using Flutter.")
      setProductLink("/products/bielearn")

    } else if (activeDiv === "washmart-image") {
      setHeading("Washmart");
      setSubHeading("Simple, Reliable, and Affordable.");
      setdescription("Digitalize your laundry with efficient laundry services, management tools, and simplified ways to track your customers within a single platform.")
      setProductLink("http://www.washmartindia.in/");

    } else if (activeDiv === "bus-tracking-img") {
      setHeading("Bus Tracking");
      setSubHeading("Live Readings & Updates");
      setdescription("Bus tracking is a modern and ideal solution to manage the local transport system. This project consists of an loT device installed on...")
      setProductLink("/products/bustracking");
    } else if (activeDiv === "drop-img") {
      setHeading("Drop");
      setSubHeading("Water Monitoring & Controlling");
      setdescription("Monitoring Water storage irrespective of their storage capacities was the main objective behind this product. It contains a pack of water...")
      setProductLink("/products/drop");
    } else if (activeDiv === "sem-img") {
      setHeading("SEM");
      setSubHeading("Electricity Monitoring & Controlling");
      setdescription("SEM(Smart Electricity Management) is definitive & durable solution for electricity consumption, monitoring, and control...")
      setProductLink("/products/sem");
    }
  }

  return (
    <div className="product-main-container slide">
      <div className="product-sub-container">
        <div className="product-left-content-div">
          <h1>{heading}</h1>
          <div className="product-text-container">
            <p>{subheading}</p>
            <p id='text-product'>
              {description}
            </p>
          </div>
          <div className="prod-readmore">
            <Link to={productLink}>
              <h2>Read More &nbsp;</h2>
              <img src="/images/readmore.png" alt='/' />
            </Link>
          </div>
        </div>
        <div className="product-incubator-image-div">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            centeredSlides={false}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              360: {
                slidesPerView: 1,
                // spaceBetween: 0,
              },

              1024: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              1080: {
                slidesPerView: 2,
                spaceBetween: 10,
              }
            }}
            mousewheel={true}
            zoom={true}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation={false}
            onActiveIndexChange={handleSlideChange}
            modules={[Zoom, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img className='product-img' src="/images/bielearn-image.png" alt='' id="bielearn-image" />
            </SwiperSlide>
            <SwiperSlide>
              <img className='product-img' src="/images/washmartLogo.png" alt='' id="washmart-image" />
            </SwiperSlide>
            <SwiperSlide>
              <img className='product-img' src="/images/BusTraking.png" alt='' id="bus-tracking-img" />
            </SwiperSlide>
            <SwiperSlide>
              <img className='product-img' src="/images/Drop.png" alt='' id="drop-img" />
            </SwiperSlide>
            <SwiperSlide>
              <img className='product-img' src="/images/SEM Image.png" alt='' id="sem-img" />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="product-vectors">
          <svg width="25" height="35" viewBox="0 0 25 35" fill="none" xmlns="http://www.w3.org/2000/svg" id="product-vector-1">
            <path d="M20.3246 32.0608L2.06956 20.5244L13.7065 2.11013C23.0983 8.92931 25.9511 21.7806 20.3246 32.0608Z" stroke="#FF8402" strokeWidth="3" />
          </svg>

          <svg width="41" height="57" viewBox="0 0 41 57" fill="none" xmlns="http://www.w3.org/2000/svg" id="product-vector-2">
            <path d="M28.5977 0L0.000218118 28.3257L28.1097 56.7048C43.9048 41.0597 44.1233 15.6746 28.5977 0Z" fill="#FF8402" />
          </svg>

          <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg" id="product-vector-3">
            <path d="M2.37335 27.2625L8.75867 2.42016L33.8138 8.86017C29.64 22.0551 16.0701 30.013 2.37335 27.2625Z" stroke="#FF8402" strokeWidth="3" />
          </svg>

          <svg width="35" height="48" viewBox="0 0 35 48" fill="none" xmlns="http://www.w3.org/2000/svg" id="product-vector-4">
            <path d="M10.4805 47.1445L34.1433 23.4817L10.6611 -0.000449853C-2.40854 13.0692 -2.4894 34.1747 10.4805 47.1445Z" fill="#FF8402" />
          </svg>

          <svg width="82" height="39" viewBox="0 0 82 39" fill="none" xmlns="http://www.w3.org/2000/svg" id="product-arrow">
            <path d="M5 5L42 33L77 5" stroke="#E9E5D9" strokeWidth="9" strokeLinecap="round" />
          </svg>

          <img src="/images/three-circles.png" id="product-three-circle" alt='/' />

          <svg width="38" height="131" viewBox="0 0 38 131" fill="none" xmlns="http://www.w3.org/2000/svg" id="product-color-circles">
            <path d="M37.0781 17.6807C36.7492 26.7853 29.0977 33.9032 19.9932 33.5742C10.8886 33.2453 3.7707 25.5938 4.09963 16.4893C4.42856 7.38474 12.08 0.266831 21.1846 0.595759C30.2895 0.91582 37.4074 8.56731 37.0781 17.6807Z" fill="#FF8402" />
            <path d="M35.2695 67.6475C34.9406 76.7521 27.2891 83.87 18.1846 83.541C9.08001 83.2121 1.96211 75.5606 2.29103 66.4561C2.61996 57.3515 10.2715 50.2336 19.376 50.5626C28.4809 50.8826 35.5988 58.5341 35.2695 67.6475Z" fill="#B2AFAF" />
            <path d="M33.5742 114.618C33.2453 123.723 25.5938 130.841 16.4892 130.512C7.3847 130.183 0.266794 122.531 0.595721 113.427C0.924649 104.322 8.57614 97.2043 17.6807 97.5333C26.7856 97.8533 33.9035 105.505 33.5742 114.618Z" fill="white" />
          </svg>

          <svg width="128" height="133" viewBox="0 0 128 133" fill="none" xmlns="http://www.w3.org/2000/svg" id="product-end-corner-vector">
            <path d="M128.221 137.711L127.06 0.000146619C56.3287 0.596623 -0.479972 62.7457 0.161318 138.791L128.221 137.711Z" fill="#444647" />
          </svg>

          <img src="/images/white-Vector.png" id="product-white-Vector" alt="/" />

        </div>
      </div>
    </div>
  );
}

export default Product;
