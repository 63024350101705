
import { createSlice } from "@reduxjs/toolkit";

const initialState = [
    {id: '1', User_name: 'Vishal lohbande', User_number: '9307945377' ,User_Email: 'vishallohbande@biencaps.com', User_comment: 'hello from vishal , i want info about your company'}
]

const contactSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
    userInfoSubmit(state, action){
        state.push(action.payload)
    },
    }
})

export const selectAllContactUser = (state) => state.userInfo;
export const { userInfoSubmit} = contactSlice.actions;
export default contactSlice.reducer