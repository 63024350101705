import React, { useEffect, useState } from 'react'
import './BottomContact.css'
function BottomContact() {
    const [linkSrc, setLinkSrc] = useState("/images/logoWhite.png")
    useEffect(()=>{
        if (window.location.pathname === "/about-us" || window.location.pathname === "/products/bielearn") {
            setLinkSrc("/images/logo.png")
        }else{
            setLinkSrc("/images/logoWhite.png")
        }
    },[])

    const handleUnview = (e) => {
        if (window.location.pathname === "/about-us" || window.location.pathname === "/products/bielearn") {
            if (e === 1) {
                document.getElementById("linkedIn").src = "/images/darkLinked.png";
            } else if (e === 2) {
                document.getElementById("behance").src = "/images/darkBe.png";
            } else if (e === 3) {
                document.getElementById("instagram").src = "/images/darkInsta.png";
            } else if (e === 4) {
                document.getElementById("youtube").src = "/images/darkyt.png";
            } else if (e === 5) {
                document.getElementById("facebook").src = "/images/darkFacebook.png";
            }
        } else {
            if (e === 1) {
                document.getElementById("linkedIn").src = "/images/linkedin.png";
            } else if (e === 2) {
                document.getElementById("behance").src = "/images/be.png";
            } else if (e === 3) {
                document.getElementById("instagram").src = "/images/instagram.png";
            } else if (e === 4) {
                document.getElementById("youtube").src = "/images/youtube.png";
            } else if (e === 5) {
                document.getElementById("facebook").src = "/images/facebook.png";
            }
        }
    }
    const handleView = (e) => {

        if (e === 1) {
            document.getElementById("linkedIn").src = "/images/linkedInColor.png";
        } else if (e === 2) {
            document.getElementById("behance").src = "/images/beColor.png";
        } else if (e === 3) {
            document.getElementById("instagram").src = "/images/instaColor.png";
        } else if (e === 4) {
            document.getElementById("youtube").src = "/images/youtubeColor.png";
        } else if (e === 5) {
            document.getElementById("facebook").src = "/images/facebookColor.png";
        }
    }
    return (
        <div className="BottomContact-main-container slide" id="AboutClickBottomContact">
            <div className='bottomcontact-left-container'>
                <div className='bottom-left-content'>
                    <div className='bottom-text'>
                        <p>We'd love to help you <br /> start exceeding your goals...</p>
                    </div>
                    <div className='bottom-contact-left-logo'>
                        <div className='bottomcontact-logo'><img src={linkSrc} alt="/" /></div>
                        <div className='contact-bottom-copyright'><p>copyright@2023 Biencaps Systems Pvt. Ltd.</p></div>
                    </div>
                </div>
            </div>
            <div className='bottomcontact-right-container'>
                <div className='bottomcontact-social-media-logos'>
                    <a href="https://www.linkedin.com/company/biencaps-systems-india-pvt-ltd/mycompany/" ><img id='linkedIn' onMouseEnter={() => handleView(1)} onMouseLeave={() => handleUnview(1)} src="/images/linkedin.png" alt="/" /></a>
                    <a href="https://www.behance.net/BiencapsSystemsPune"><img id='behance' onMouseEnter={() => handleView(2)} onMouseLeave={() => handleUnview(2)} src="/images/be.png" alt="/" /></a>
                    <a href="https://www.instagram.com/biencaps_systems/"><img id='instagram' onMouseEnter={() => handleView(3)} onMouseLeave={() => handleUnview(3)} src="/images/instagram.png" alt="/" /></a>
                    <a href="https://www.youtube.com/@biencapssystems2840"><img id='youtube' onMouseEnter={() => handleView(4)} onMouseLeave={() => handleUnview(4)} src="/images/youtube.png" alt="/" /></a>
                    <a href="https://www.facebook.com/biencapssystems/"> <img id='facebook' onMouseEnter={() => handleView(5)} onMouseLeave={() => handleUnview(5)} src="/images/facebook.png" alt="/" /></a>
                </div>
                <div className='bottom-contact-rightcontainer-right'>
                    <div className='bottomcontact-info'>
                        <div className='h'>Connect with Us</div>
                        <div className='mobile-number'>
                            <img id='phone' src="/images/phone.png" alt="/" />
                            <p><a href="tel:+91 9527 837 343">+91 9527 837 343 </a></p>
                        </div>
                        <div className='bottomcontact-mail'>
                            <img id='mailimg' src="/images/mail.png" alt="/" />
                            <a id='mail' href="mailto:info@biencaps.com">info@biencaps.com</a>
                        </div>

                        <div className='adress'>
                            <img src="/images/location.png" alt="" id="adress-icon" />
                            <p> <a href='https://www.google.com/maps/place/Core2web+Pune/@18.4537191,73.8270261,19z/data=!3m1!4b1!4m6!3m5!1s0x3bc295b76698ba23:0xea5a0c7f45d79e89!8m2!3d18.4537178!4d73.8276698!16s%2Fg%2F11gh9nzjxd?entry=ttu' target='_blank' rel="noreferrer">
                                3rd Floor, Walhekar Properties, <br />above HDFC Bank, Narhe, Pune- 411041</a>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BottomContact