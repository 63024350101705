import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./styleBielearnSwiper.css";
import { Autoplay} from 'swiper/modules';
function BielearnSwiper() {
    return (
        <div className='bielearn-swiper'>
            <div className="bielearn-swiper-container">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 50,
                        },
                        1530:{
                            slidesPerView: 5,
                            spaceBetween: 50,
                        }

                    }}
                    mousewheel={true}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={false}
                    // modules={[Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='bie-containt-container'>
                            <div className='bie-containt-text'>
                                <h3>Core2Web Technologies</h3>
                                <p className='bie-sir-name'>Shashi Sir</p>
                                <p className='bie-address'>2nd Floor, Walhekar Properties, Near HDFC Bank, Narhe, Pune - 411041</p>
                            </div>
                            <div className='bie-users'>
                                <img src='/images/usersCount.png' alt='/' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bie-containt-container'>
                            <div className='bie-containt-text'>
                                <h3>Core2Web Technologies</h3>
                                <p className='bie-sir-name'>Shashi Sir</p>
                                <p className='bie-address'>2nd Floor, Walhekar Properties, Near HDFC Bank, Narhe, Pune - 411041</p>
                            </div>
                            <div className='bie-users'>
                                <img src='/images/usersCount.png' alt='/' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bie-containt-container'>
                            <div className='bie-containt-text'>
                                <h3>Core2Web Technologies</h3>
                                <p className='bie-sir-name'>Shashi Sir</p>
                                <p className='bie-address'>2nd Floor, Walhekar Properties, Near HDFC Bank, Narhe, Pune - 411041</p>
                            </div>
                            <div className='bie-users'>
                                <img src='/images/usersCount.png' alt='/' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bie-containt-container'>
                            <div className='bie-containt-text'>
                                <h3>Core2Web Technologies</h3>
                                <p className='bie-sir-name'>Shashi Sir</p>
                                <p className='bie-address'>2nd Floor, Walhekar Properties, Near HDFC Bank, Narhe, Pune - 411041</p>
                            </div>
                            <div className='bie-users'>
                                <img src='/images/usersCount.png' alt='/' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bie-containt-container'>
                            <div className='bie-containt-text'>
                                <h3>Core2Web Technologies</h3>
                                <p className='bie-sir-name'>Shashi Sir</p>
                                <p className='bie-address'>2nd Floor, Walhekar Properties, Near HDFC Bank, Narhe, Pune - 411041</p>
                            </div>
                            <div className='bie-users'>
                                <img src='/images/usersCount.png' alt='/' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide><div className='bie-containt-container'>
                        <div className='bie-containt-text'>
                            <h3>Core2Web Technologies</h3>
                            <p className='bie-sir-name'>Shashi Sir</p>
                            <p className='bie-address'>2nd Floor, Walhekar Properties, Near HDFC Bank, Narhe, Pune - 411041</p>
                        </div>
                        <div className='bie-users'>
                            <img src='/images/usersCount.png' alt='/' />
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bie-containt-container'>
                            <div className='bie-containt-text'>
                                <h3>Core2Web Technologies</h3>
                                <p className='bie-sir-name'>Shashi Sir</p>
                                <p className='bie-address'>2nd Floor, Walhekar Properties, Near HDFC Bank, Narhe, Pune - 411041</p>
                            </div>
                            <div className='bie-users'>
                                <img src='/images/usersCount.png' alt='/' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bie-containt-container'>
                            <div className='bie-containt-text'>
                                <h3>Core2Web Technologies</h3>
                                <p className='bie-sir-name'>Shashi Sir</p>
                                <p className='bie-address'>2nd Floor, Walhekar Properties, Near HDFC Bank, Narhe, Pune - 411041</p>
                            </div>
                            <div className='bie-users'>
                                <img src='/images/usersCount.png' alt='/' />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='bie-containt-container'>
                            <div className='bie-containt-text'>
                                <h3>Core2Web Technologies</h3>
                                <p className='bie-sir-name'>Shashi Sir</p>
                                <p className='bie-address'>2nd Floor, Walhekar Properties, Near HDFC Bank, Narhe, Pune - 411041</p>
                            </div>
                            <div className='bie-users'>
                                <img src='/images/usersCount.png' alt='/' />
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default BielearnSwiper
