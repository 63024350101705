import React, { useEffect, useState } from 'react';
import Landing1 from "./pages/home/Landing1";
import Technologies from "./pages/technologies/Technologies";
import About from "./pages/About/About";
import BottomContact from "./components/BottomContact/BottomContact";
import Contact from "./pages/Contact/Contact";
import Portfolio from "./pages/Portfolio/Portfolio";
import Service from "./pages/Service/Service";
import Product from "./pages/product/Product";
function HomeNavigate() {
  const [currentPath] = useState(window.sessionStorage.getItem("currentP"))
  useEffect(() => {
    const ele = document.getElementById("home-navigate");
    if (ele.scrollTop < ele.clientHeight) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "none";
      document.querySelector(".mobile-nav-toggle").classList.add("active");
      document.querySelector(".item-list").classList.add("active");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";
      if (ele.clientWidth > 599) {
        document.getElementById("menu-toggle-container").style.display = "none";
        document.querySelector(".mobile-nav-toggle").classList.remove("active");
      }

    } else if (ele.scrollTop > (ele.clientHeight * 6) - 2) {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "logoWhite.svg";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
    } else if (ele.scrollTop > ele.clientHeight * 5) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".item-list").classList.remove("active");
    } else if (ele.scrollTop > ele.clientHeight * 4) {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "logoWhite.svg";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".item-list").classList.remove("active");
    } else if (ele.scrollTop > ele.clientHeight * 3) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".item-list").classList.remove("active");
    } else if (ele.scrollTop > ele.clientHeight * 2) {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "logoWhite.svg";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
      document.getElementById("menu-toggle-container").style.display = "flex";
    } else {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "logoWhite.svg";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";
    }
    handleScroll()

    if (ele !== null && window.location.pathname === "/") {
      ele.scrollTop = currentPath;
    }

    if (ele !== null && window.location.pathname === "/") {
      ele.scrollTop = currentPath;
    }

  }, [])

  const handleScroll = () => {
    const ele = document.getElementById("home-navigate");
    if (ele.scrollTop < ele.clientHeight) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "none";
      document.querySelector(".mobile-nav-toggle").classList.add("active");
      document.querySelector(".item-list").classList.add("active");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";
      if (ele.clientWidth > 599) {
        document.getElementById("menu-toggle-container").style.display = "none";
        document.querySelector(".mobile-nav-toggle").classList.remove("active");
      }

    } else if (ele.scrollTop > (ele.clientHeight * 6) - 2) {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "logoWhite.svg";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
    } else if (ele.scrollTop > ele.clientHeight * 5) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
    } else if (ele.scrollTop > ele.clientHeight * 4) {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "logoWhite.svg";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
    } else if (ele.scrollTop > ele.clientHeight * 3) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
    } else if (ele.scrollTop > ele.clientHeight * 2) {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "logoWhite.svg";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
    } else {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "logoWhite.svg";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";
    }
    window.sessionStorage.setItem("currentP", ele.scrollTop)
  };


  return (
    <div style={{ height: "100vh", width: "100%", overflow: "auto", position: "relative" }} id="home-navigate" onScroll={handleScroll}>
      <Landing1 />
      <Product />
      <Service />
      <Technologies />
      <Portfolio />
      <About />
      <Contact />
      <BottomContact />
    </div>
  );
}

export default HomeNavigate;

