import React from "react";
import "../Portfolio/Portfolio.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
function Portfolio() {
  const navigate = useNavigate();

  const routeIOtPage = () => {
    // window.location.href = '/portfolio/internet-of-things';
    navigate('/portfolio/internet-of-things')
  }

  const routeWashmartPage = () => {
    // window.location.href = '/portfolios/washmart'
    navigate('/portfolios/washmart')
  }

  const routeSocialmediaPage = () => {
    // window.location.href = "/portfolios/social-media"
    navigate("/portfolios/social-media");
  }

  const routeGameWowPage = () => {
    // window.location.href = '/portfolios/game-development'
    navigate('/portfolios/game-development')
  }

  const routeEcommercePage = () => {
    // window.location.href = "/portfolios/e-commerce";
    navigate("/portfolios/e-commerce")
  }

  const routeElearningPage = () => {
    // window.location.href = '/portfolio/e-learning';
    navigate("/portfolio/e-learning")
  }

  return (
    <div className="portfolio-main-container slide" id="portfolio-div">
      <div className="portfolio-heading">
        <div className="portfolio-heading-subdiv">
          <div className="portfolio-heading-left-vector">
            <svg width="26" height="36" viewBox="0 0 26 36" fill="none" xmlns="http://www.w3.org/2000/svg" id="portfolio-vector-1">
              <path d="M20.9949 33.1596L2.06888 21.1992L14.1332 2.10877C23.8937 9.16369 26.8552 22.5053 20.9949 33.1596Z" stroke="#FF8402" strokeWidth="3" />
            </svg>

          </div>
          <div className="portfolio-heading-mainheading">
            <span>PORTFOLIO</span>
            <h3>Gallery</h3>
          </div>
          <div className="portfolio-heading-right-vector">

            <svg width="32" height="45" viewBox="0 0 32 45" fill="none" xmlns="http://www.w3.org/2000/svg" id="portfolio-vector-2">
              <path d="M22.2578 0L-0.00145811 22.0477L21.878 44.1371C34.1724 31.9595 34.3424 12.2006 22.2578 0Z" fill="#B2AFAF" />
            </svg>

          </div>
        </div>
      </div>

      <div className="portfolio-main-Slider">
        <div className="slider">
          <div className="actual-slider">
            <Swiper
              slidesPerView={5}
              spaceBetween={30}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              mousewheel={true}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{

                600:{
                  slidesPerView:2,
                  spaceBetween:10
                },
                700:{
                  slidesPerView:5,
                  spaceBetween:30
                },
                1080:{
                  slidesPerView:5,
                  spaceBetween:30
                },
                1920:{
                  slidesPerView:5,
                  spaceBetween:30
                }
              }}
              navigation={false}
              modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="Slider-card-1">
                  <div className="Slider-card-content  Iot-background" onClick={routeIOtPage}>
                    <div className="card-content-heading-para">
                      <h1 id="card-iot-heading">IOT</h1>
                      <p>Biencaps unlocks the value of connected devices and data analytics to... </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Slider-card-1">
                  <div className="Slider-card-content Ecommerce-background" onClick={routeEcommercePage}>
                    <div className="card-content-heading-para">
                      <h1>E-Commerce</h1>
                      <p>Drive online success with our tailored e-commerce solutions...</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Slider-card-1">
                  <div className="Slider-card-content  ELearning-background" onClick={routeElearningPage}>
                    <div className="card-content-heading-para">
                      <h1>E-Learning</h1>
                      <p>Revolutionize education with our innovative e-learning solutions...</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Slider-card-1">
                  <div className="Slider-card-content GameDevelopment-background" onClick={routeGameWowPage}>
                    <div className="card-content-heading-para">
                      <h1 id="card-game-development-heading">Game Development</h1>
                      <p>Elevate user experiences with our cutting-edge game development solutions...</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Slider-card-1">
                  <div className="Slider-card-content  Cms-background" onClick={routeWashmartPage}>
                    <div className="card-content-heading-para">
                      <h1>CMS</h1>
                      <p>Empower your content management with our robust CMS solutions ...</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="Slider-card-1">
                  <div className="Slider-card-content SocialMedia-background" onClick={routeSocialmediaPage}>
                    <div className="card-content-heading-para">
                      <h1>Social Media</h1>
                      <p>Elevate engagement, connectivity, and user experience with our innovative solutions...</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

            </Swiper>
          </div>
        </div>
      </div>

      <svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg" id="portfolio-vector-3">
        <path d="M20.1602 30.2129L25.4321 5.72695L1.13304 0.495271C-1.77879 14.0196 6.73911 27.3233 20.1602 30.2129Z" fill="#FF8402" />
      </svg>

      <svg width="37" height="30" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg" id="portfolio-vector-4">
        <path d="M1.83699 27.5624L8.45225 1.82551L34.4088 8.49723C30.1064 22.1851 16.0316 30.4389 1.83699 27.5624Z" stroke="#FF8402" strokeWidth="3" />
      </svg>

      <svg width="82" height="39" viewBox="0 0 82 39" fill="none" xmlns="http://www.w3.org/2000/svg" id="portfolio-arrow">
        <path d="M5 5L42 33L77 5" stroke="#E9E5D9" strokeWidth="9" strokeLinecap="round" />
      </svg>
      <div className="slider-bottom">
        <svg className="posvg1" width="23" height="89" viewBox="0 0 23 89" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.581522 77.9466C0.26036 71.7834 4.99884 66.5239 11.162 66.2028C17.3252 65.8816 22.5847 70.6201 22.9059 76.7833C23.227 82.9465 18.4886 88.2059 12.3254 88.5271C6.16247 88.8543 0.902997 84.1158 0.581522 77.9466Z" fill="#FF8402" />
          <path d="M0.581522 44.1224C0.26036 37.9592 4.99884 32.6997 11.162 32.3785C17.3252 32.0574 22.5847 36.7959 22.9059 42.959C23.227 49.1222 18.4886 54.3817 12.3254 54.7029C6.16247 55.03 0.902997 50.2916 0.581522 44.1224Z" fill="#B2AFAF" />
          <path d="M0.581522 12.3255C0.26036 6.16229 4.99884 0.90282 11.162 0.581658C17.3252 0.260495 22.5847 4.99898 22.9059 11.1622C23.227 17.3254 18.4886 22.5848 12.3254 22.906C6.16247 23.2332 0.902997 18.4947 0.581522 12.3255Z" fill="#E9E5D9" />
        </svg>
        <svg className="posvg2" width="92" height="80" viewBox="0 0 92 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M104 104V0C46.5579 0 7.62939e-06 46.5579 7.62939e-06 104H104Z" fill="white" />
        </svg>
      </div>

    </div>
  );
}

export default Portfolio;
