import React, { useEffect } from 'react'
import '../product/BusTracking.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';
import BottomContact from '../../components/BottomContact/BottomContact';

function BusTracking() {
  useEffect(() => {
    const ele = document.getElementById("home-busTracking");
    if (ele.scrollTop < ele.clientHeight) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "none";
      document.querySelector(".mobile-nav-toggle").classList.add("active");
      document.querySelector(".item-list").classList.add("active");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";

    }
  }, [])
  const handleScroll = () => {
    const ele = document.getElementById("home-busTracking");
    if (ele.scrollTop < ele.clientHeight) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "none";
      document.querySelector(".mobile-nav-toggle").classList.add("active");
      document.querySelector(".item-list").classList.add("active");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";

    } else if (ele.scrollTop < (ele.clientHeight * 2) - 2) {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "/images/logoWhite.png";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";

    } else if (ele.scrollTop < (ele.clientHeight * 3) - 2) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";
      document.querySelector(".item-list").classList.remove("active");
      document.querySelector(".mobile-nav-toggle").classList.remove("active");

    } else if (ele.scrollTop > ele.clientHeight * 3.48) {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "/images/logoWhite.png";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";

    } else {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";
      document.querySelector(".item-list").classList.remove("active");
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
    }
  }

  const routeToContactUs = () => {
    document.getElementById('blur-div').style.visibility = 'visible'
  }
  return (
    <div className='drop-main-container' style={{ height: "100vh", width: "100%", overflow: "auto" }} id="home-busTracking" onScroll={handleScroll}>


      {/*Bustracking  First page  */}
      <div className="Drop-first-page">
        <div className="Drop-first-page-sub-div">
          <svg width="25" height="35" viewBox="0 0 25 35" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-1">
            <path d="M20.3246 32.0608L2.06956 20.5244L13.7065 2.11013C23.0983 8.92931 25.9511 21.7806 20.3246 32.0608Z" stroke="#FF8402" strokeWidth="3" />
          </svg>
          <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-2">
            <path d="M31.5156 47.5254L40.2967 8.71345L1.78099 -0.000564967C-3.06902 21.4364 10.2423 42.7124 31.5156 47.5254Z" fill="#E9E5D9" />
          </svg>

          <svg width="36" height="29" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-3">
            <path d="M1.83624 26.6688L8.22156 1.82641L33.2767 8.26642C29.1029 21.4613 15.533 29.4193 1.83624 26.6688Z" stroke="#FF8402" strokeWidth="3" />
          </svg>

          <svg width="36" height="29" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-4">
            <path d="M1.83624 26.6688L8.22156 1.82641L33.2767 8.26642C29.1029 21.4613 15.533 29.4193 1.83624 26.6688Z" stroke="#272D2F" strokeWidth="3" />
          </svg>

          <svg width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-5">
            <path d="M0 26.5L32.7491 33.3813L39.5779 0.882174C21.4896 -2.91856 3.77172 8.54981 0 26.5Z" fill="#FF8402" />
          </svg>

          <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-6">
            <path d="M31.5156 47.5234L40.2967 8.71149L1.78099 -0.00251809C-3.06902 21.4344 10.2423 42.7104 31.5156 47.5234Z" fill="#E9E5D9" />
          </svg>

          <div className="Drop-first-page-sub-container">
            <div className="Drop-first-page-text-content">
              <div className="Drop-heading">
                <h1 id="Drop">Bus Tracking</h1>
              </div>
              <div className="Drop-paragraph">
                <p>
                  Bus tracking is a modern and ideal solution to manage the local transport system. This project consists of an loT device installed on each bus to track them along with a LED display at each bus stop to display bus statuses. The LED displays on the bus stops show the live tracking of buses and the expected time of arrival to ease the transport.
                </p>
              </div>
              <div className="Drop-enquire-now BusTracking-enqiure-now">
                <div className='drop-enquire-now-btn connect' id='connectBtn' onClick={() => routeToContactUs()}><p>Enquire Now</p></div>
              </div>
            </div>
            <div className="Drop-first-image-content">
              <img src="/images/BusTraking.png" alt="/" id="Drop-image" />
            </div>
          </div>
        </div>
      </div>
      {/*Bustracking First page end */}

      {/* Bus tracking Second page Start */}

      <div className='Bustracking-second-page'>

        <div className="product-in-glanace">
          <div className="">
            <h1>Product in a glance</h1>
          </div>
          <div>
            <svg width="146" height="21" viewBox="0 0 146 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10.5" cy="10.5" r="10.5" fill="#FF8402" />
              <circle cx="52.5" cy="11.5" r="7.5" fill="white" fill-opacity="0.37" />
              <circle cx="95.5" cy="11.5" r="7.5" fill="white" fill-opacity="0.37" />
              <circle cx="138.5" cy="11.5" r="7.5" fill="white" fill-opacity="0.37" />
            </svg>
          </div>
        </div>

        <div className="Drop-secondpage-cards-sem">
          <div className="Drop-secondpage-cards-scroller drop-product">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              mousewheel={true}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
                1530: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
                1700: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                }

              }}
              navigation={false}
              modules={[Autoplay]}
              className="mySwiper"
            ><SwiperSlide>
                <div className="Drop-second-page-card-1" id="Bustracking-second-page-card-1">
                  <img src="/images/DropSecond-page-card-1-img.png" alt="/" />
                  <h1>RT-DAS</h1>
                  <p>Real-time data acquisition system for Bus tracking unit armed with multiple wireless interfaces.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Drop-second-page-card-1 " id="Bustracking-second-page-card-1">
                  <img src="/images/BustrackingSecond-page-2-image.png" alt="/" />
                  <h1>Accuracy</h1>
                  <p>Bus-Installed Device helps predict accurate time to travel the distance with corrections from traffic delays.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Drop-second-page-card-1 " id="Bustracking-second-page-card-1">
                  <img src="/images/DropSecond-page-card-3-img.png" alt="/" />
                  <h1>Standalone</h1>
                  <p>Multiple fail-safe mechanisms assure loss-less data gathering independent of user interventions.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Drop-second-page-card-1 " id="Bustracking-second-page-card-1">
                  <img src="/images/DropSecond-page-card-4-img.png" alt="/" />
                  <h1>Cost Efficiency</h1>
                  <p>One-time installation & less maintenance makes Bus Tracking more reliable and durable.</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      {/* Bus tracking Second page End */}


      {/* Third page start */}
      <div className="Drop-third-page">
        <div className="Drop-third-page-sub-div">
          <div className="Drop-third-page-left-content">
            <div className="Drop-how-it-work">
              <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg" id="how-it-work-circle">
                <circle cx="50.5" cy="50.5" r="50.5" fill="#E9E5D9" />
              </svg>
              <h1>How it work</h1>
              <svg width="25" height="35" viewBox="0 0 25 35" fill="none" xmlns="http://www.w3.org/2000/svg" id="how-it-work-vector">
                <path d="M20.3246 32.0608L2.06956 20.5244L13.7065 2.11013C23.0983 8.92931 25.9511 21.7806 20.3246 32.0608Z" stroke="#FF8402" strokeWidth="3" />
              </svg>
            </div>

            <div className="drop-third-page-left-content">
              <div className="step-div">
                <div className="step01 step02">
                  <h1>Step 02</h1>
                </div>
                <img src="/images/Bus-Step02.png" id="Step-left-image" alt="/" />
              </div>
              <div className="third-page-left-paragaph" id="third-page-left-paragaph-bustraking">
                <h1>LED Matrix Display Set-Up</h1>
                <p>The LED display is a set of LED matrix and a receiver end that receives the signals emitted from the bus and updates the bus information on the LED display.</p>
              </div>
            </div>
          </div>
          <div className="Drop-third-page-middle-content">
            <svg width="62" height="850" viewBox="0 0 62 850" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="32.5" y1="70.5" x2="32.5" y2="388.5" stroke="black" stroke-opacity="0.5" strokeWidth="5" stroke-linecap="round" stroke-dasharray="20 20" />
              <line x1="32.5" y1="472.5" x2="32.5" y2="775.5" stroke="black" stroke-opacity="0.5" strokeWidth="5" stroke-linecap="round" stroke-dasharray="20 20" />
              <circle cx="31" cy="31" r="29.5" stroke="#0065B8" strokeWidth="3" />
              <circle cx="31" cy="31" r="15" fill="#0065B8" />
              <circle cx="31" cy="819" r="29.5" stroke="#FF8402" strokeWidth="3" />
              <circle cx="31" cy="819" r="15" fill="#FF8402" />
              <circle cx="31" cy="430" r="29.5" stroke="#008F88" strokeWidth="3" />
              <circle cx="31" cy="430" r="15" fill="#008F88" />
            </svg>

          </div>
          <div className="Drop-third-page-right-content">
            <div className="Third-right-div-1">
              <img src="/images/step01-image.png" id="step01-image" alt="/" />
              <div className="step01">
                <h1>Step 01</h1>
              </div>
              <div className="step-1-paragraph">

                <h1>Device Installation</h1>
                <p>All the local transport buses get installed with an IoT device that helps tracking the buses. The receiver end receives signals from the bus once it reaches within the vicinity of 1-2 KM.</p>
              </div>
            </div>

            <div className="Third-right-div-1 Third-right-div-2">

              <img src="/images/BusStep03.png" id="step03-image" alt="/" />
              <div className="step-1-paragraph step-3-paragraph ">
                <div className="step01 step03">
                  <h1>Step 03</h1>
                </div>
                <h1>Live Readings & Updates</h1>
                <p>The LED monitors display the live status of buses within the vicinity of receiver diameter along with their expected time of arrival. The system makes corrections due to traffic delays as well.</p>
              </div>
            </div>

          </div>
        </div>

        {/* Drop-third-page-sub-div for mobile*/}
        <div className="Drop-third-page-sub-div-mobile">
          <img src="/images/step-line.png" alt="/" id="step-line" />
          <div className="Drop-third-page-sub-div-mobile-content">
            <div className="mobile-step01-div">
              <div className="step01-mobile"><p className="step01-mobile-head">Step 01</p></div>
              <h3 id="step01-h3">Device installation</h3>
              <p style={{ textAlign: 'left' }}>All the local transport buses get installed with an IoT device that helps tracking the buses. The receiver end receives signals from the bus once it reaches within the vicinity of 1-2 KM.</p>
            </div>
            <div className="mobile-step01-div">
              <div className="step01-mobile" style={{ border: "1px solid #008F88" }}><p className="step01-mobile-head" style={{ backgroundColor: "#008F88" }}>Step 02</p></div>
              <h3 id="step01-h3">LED Matrix Display Set-Up</h3>
              <p style={{ textAlign: 'left' }}>The LED display is a set of LED matrix and a receiver end that receives the signals emitted from the bus and updates the bus information on the LED display.</p>
            </div>
            <div className="mobile-step01-div">
              <div className="step01-mobile" style={{ border: "1px solid #FF8402" }}><p className="step01-mobile-head" style={{ backgroundColor: "#FF8402" }}>Step 03</p></div>
              <h3 id="step01-h3">Live Readings & Updates</h3>
              <p style={{ textAlign: 'left' }}>The LED monitors display the live status of buses within the vicinity of receiver diameter along with their expected time of arrival. The system makes corrections due to traffic delays as well.</p>
            </div>
          </div>
        </div>

      </div>
      {/* Third page End */}


      {/* Fourth Page */}
      <div className="Bustracking-fourth-page">
        <div className='bustracking-fourth-page-inner-div'>
          <div className="bustracking-3-page-div-1">
            <svg width="135" height="135" viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M62.763 78.1975C64.2649 82.2102 65.7512 85.0985 67.2202 86.8631C68.6871 88.6264 70.621 89.1909 73.0146 88.5495C73.4637 88.4292 73.8469 88.2471 74.2264 88.0643C74.6497 88.032 75.0677 87.9994 75.5151 87.8795C77.9055 87.239 79.2947 85.7841 79.69 83.5218C80.0767 81.26 79.9213 78.0151 79.214 73.7895L72.8187 41.4204C71.5559 35.115 70.5626 30.6117 69.8397 27.9137C68.8549 24.2386 67.1242 21.6291 64.6522 20.0892C62.1715 18.5499 59.36 18.1977 56.2179 19.0396C56.0534 19.0837 55.9369 19.1555 55.7791 19.2048C55.6177 19.241 55.4793 19.2375 55.3164 19.2812C52.171 20.124 49.9151 21.8322 48.5385 24.4069C47.1648 26.979 46.9707 30.1042 47.9555 33.7794C48.6788 36.479 50.0714 40.8735 52.1289 46.966L62.7729 78.1949M77.4423 99.8096C74.4005 100.625 72.0617 102.286 70.4243 104.783C68.7933 107.29 68.3425 109.911 69.0768 112.651C69.9091 115.757 71.6475 117.896 74.2925 119.081C76.9449 120.267 79.7205 120.479 82.6143 119.704C85.5655 118.913 87.9014 117.346 89.6332 115.011C91.3642 112.673 91.7994 109.934 90.9561 106.787C90.2218 104.046 88.5438 101.996 85.9256 100.627C83.3124 99.2717 80.4824 98.995 77.4357 99.8113" fill="white" />
            </svg>

            <p>Alerts on Bus Arrivals at
              bus-stop
            </p>

          </div>

          <div className="bustracking-3-page-div-2">
            <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M39.5007 37.8548C37.3181 37.8548 35.225 36.9878 33.6818 35.4446C32.1385 33.9013 31.2715 31.8082 31.2715 29.6257C31.2715 27.4431 32.1385 25.35 33.6818 23.8068C35.225 22.2635 37.3181 21.3965 39.5007 21.3965C41.6832 21.3965 43.7763 22.2635 45.3195 23.8068C46.8628 25.35 47.7298 27.4431 47.7298 29.6257C47.7298 30.7063 47.517 31.7764 47.1034 32.7748C46.6899 33.7732 46.0837 34.6804 45.3195 35.4446C44.5554 36.2087 43.6482 36.8149 42.6498 37.2284C41.6514 37.642 40.5813 37.8548 39.5007 37.8548ZM39.5007 6.58398C33.3896 6.58398 27.5289 9.01158 23.2077 13.3327C18.8866 17.6539 16.459 23.5146 16.459 29.6257C16.459 46.9069 39.5007 72.4173 39.5007 72.4173C39.5007 72.4173 62.5423 46.9069 62.5423 29.6257C62.5423 23.5146 60.1147 17.6539 55.7936 13.3327C51.4724 9.01158 45.6117 6.58398 39.5007 6.58398Z" fill="white" />
            </svg>
            <p>Real-time Bus
              tracking with traffic
              corrections &
              accuracy.
            </p>
          </div>

          <div className="bustracking-3-page-div-3">
            <p id="Lets">Let not forget the</p>
            <p id="p1" className='bustracking-p1'>Features</p>
          </div>

          <div className="bustracking-3-page-div-4">
            <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M67.5234 15.3652H13.209V79.3836H67.5234V15.3652Z" fill="white" />
              <path d="M60.6494 30.459H20.2168V72.5089H60.6494V30.459Z" fill="black" />
              <path d="M33.9634 55.9316H26.5508V62.5356H33.9634V55.9316Z" fill="black" />
              <path d="M33.9634 38.9512H26.5508V45.5538H33.9634V38.9512Z" fill="black" />
              <path d="M47.1718 10.2428C47.1718 9.9746 47.1718 9.70505 47.1718 9.43415C47.1718 5.25611 43.8024 1.88672 39.6244 1.88672C35.4463 1.88672 32.0769 5.25611 32.0769 9.43415C32.0769 9.7037 32.0769 9.97326 32.0769 10.2428H21.9688V15.3656V23.7203H59.032V15.2295V10.2428H47.1718Z" fill="black" />
              <path d="M60.6495 74.2611H20.2169C19.2748 74.2611 18.4648 73.4524 18.4648 72.509V30.4591C18.4648 29.517 19.2748 28.707 20.2169 28.707H60.6495C61.5929 28.707 62.403 29.517 62.403 30.4591V72.6451C62.403 73.4524 61.5929 74.2611 60.6495 74.2611ZM21.9704 70.8917H59.0336V32.2125H21.9704V70.8917Z" fill="white" />
              <path d="M67.3874 80.999C66.4453 80.999 65.6354 80.1904 65.6354 79.247V16.9807H58.8966C57.9532 16.9807 57.1445 16.1721 57.1445 15.2287C57.1445 14.2852 57.9532 13.4766 58.8966 13.4766H67.3874C68.3309 13.4766 69.1409 14.2852 69.1409 15.2287V79.247C69.1409 80.1904 68.3309 80.999 67.3874 80.999Z" fill="white" />
              <path d="M13.2085 80.999C12.2651 80.999 11.4551 80.1904 11.4551 79.2469V15.3647C11.4551 14.4213 12.2651 13.6113 13.2085 13.6113H21.9689C22.911 13.6113 23.721 14.4213 23.721 15.3647C23.721 16.3068 22.911 17.1169 21.9689 17.1169H14.8245V79.5178C14.8245 80.1904 14.0158 80.999 13.2085 80.999Z" fill="white" />
              <path d="M59.0335 25.4739H21.9703C21.0268 25.4739 20.2168 24.6639 20.2168 23.7205V10.2429C20.2168 9.30086 21.0268 8.49087 21.9703 8.49087H30.3249C30.7306 3.77373 34.7739 0 39.4911 0C44.2082 0 48.2514 3.77373 48.6558 8.49087H58.7639C59.7073 8.49087 60.516 9.30086 60.516 10.2429V23.7205C60.6494 24.6639 59.8408 25.4739 59.0335 25.4739ZM23.5862 22.1045H57.28V11.9964H47.1719C46.6341 11.9964 46.2298 11.7268 45.9603 11.4573C45.6907 11.1877 45.4212 10.6486 45.5559 10.1095C45.5559 9.83996 45.5559 9.70518 45.5559 9.43563C45.5559 6.20102 42.8604 3.50553 39.6258 3.50553C36.3912 3.50553 33.6956 6.20102 33.6956 9.43563C33.6956 9.70518 33.6956 9.83996 33.6956 10.1095C33.6956 10.6486 33.5609 11.0529 33.2913 11.4573C33.0218 11.8616 32.4827 11.9964 32.0797 11.9964H23.5862V22.1045Z" fill="white" />
              <path d="M33.8288 47.1707H26.4161C25.4727 47.1707 24.6641 46.362 24.6641 45.4186V38.8146C24.6641 37.8712 25.4727 37.0625 26.4161 37.0625H33.8288C34.7722 37.0625 35.5808 37.8712 35.5808 38.8146V45.4186C35.5795 46.3634 34.7722 47.1707 33.8288 47.1707ZM28.1669 43.8013H32.2101V40.5667H28.1669V43.8013Z" fill="white" />
              <path d="M53.91 40.5667H39.2208C38.2774 40.5667 37.4688 39.758 37.4688 38.8146C37.4688 37.8712 38.2774 37.0625 39.2208 37.0625H53.91C54.8534 37.0625 55.6634 37.8712 55.6634 38.8146C55.6634 39.758 54.8534 40.5667 53.91 40.5667Z" fill="white" />
              <path d="M49.0575 47.1721H39.2189C38.2754 47.1721 37.4668 46.3635 37.4668 45.4201C37.4668 44.4766 38.2754 43.668 39.2189 43.668H49.0575C50.001 43.668 50.8096 44.4766 50.8096 45.4201C50.6762 46.3648 50.0023 47.1721 49.0575 47.1721Z" fill="white" />
              <path d="M33.8288 64.1531H26.4161C25.4727 64.1531 24.6641 63.3444 24.6641 62.401V55.7983C24.6641 54.8549 25.4727 54.0449 26.4161 54.0449H33.8288C34.7722 54.0449 35.5808 54.8549 35.5808 55.7983V62.401C35.5795 63.3444 34.7722 64.1531 33.8288 64.1531ZM28.1669 60.7837H32.2101V57.5504H28.1669V60.7837Z" fill="white" />
              <path d="M53.91 57.5504H39.2208C38.2774 57.5504 37.4688 56.7404 37.4688 55.7983C37.4688 54.8549 38.2774 54.0449 39.2208 54.0449H53.91C54.8534 54.0449 55.6634 54.8549 55.6634 55.7983C55.6634 56.7404 54.8534 57.5504 53.91 57.5504Z" fill="white" />
              <path d="M49.0575 64.1526H39.2189C38.2754 64.1526 37.4668 63.344 37.4668 62.4005C37.4668 61.4571 38.2754 60.6484 39.2189 60.6484H49.0575C50.001 60.6484 50.8096 61.4571 50.8096 62.4005C50.6762 63.344 50.0023 64.1526 49.0575 64.1526Z" fill="white" />
              <path d="M39.6234 13.3652C37.3322 13.3652 35.4453 11.4784 35.4453 9.18586C35.4453 6.89333 37.3309 5.00781 39.6234 5.00781C41.9159 5.00781 43.8014 6.89333 43.8014 9.18586C43.8014 11.4784 41.9159 13.3652 39.6234 13.3652Z" fill="white" />
              <path d="M79.2493 81.0002H1.75346C0.810034 81.0002 0 80.1916 0 79.2482C0 78.3047 0.810034 77.4961 1.75346 77.4961H79.2493C80.1914 77.4961 81.0014 78.3047 81.0014 79.2482C80.8652 80.1916 80.0566 81.0002 79.2493 81.0002Z" fill="white" />
            </svg>

            <p>Representation of received data in a
              digital way.
            </p>
          </div>

          <div className="bustracking-3-page-div-5">
            <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_3264_5823)">
                <path d="M61.1369 52.3523L60.5041 51.794C58.7089 50.1945 57.1377 48.3603 55.8327 46.3409C54.4075 43.554 53.5533 40.5105 53.3202 37.389V28.1951C53.3325 23.2922 51.5541 18.5536 48.3189 14.8695C45.0838 11.1854 40.6147 8.8095 35.7513 8.18815V5.78732C35.7513 5.12837 35.4896 4.4964 35.0236 4.03045C34.5577 3.5645 33.9257 3.30273 33.2668 3.30273C32.6078 3.30273 31.9758 3.5645 31.5099 4.03045C31.0439 4.4964 30.7822 5.12837 30.7822 5.78732V8.22537C25.9624 8.89152 21.5473 11.2818 18.3547 14.9535C15.162 18.6252 13.4082 23.3295 13.418 28.1951V37.389C13.1849 40.5105 12.3307 43.554 10.9055 46.3409C9.62298 48.3553 8.07698 50.1892 6.30856 51.794L5.67578 52.3523V57.6007H61.1369V52.3523Z" fill="white" />
                <path d="M28.5137 59.5547C28.6769 60.7344 29.2614 61.8152 30.1594 62.5975C31.0574 63.3798 32.2081 63.8108 33.3991 63.8108C34.59 63.8108 35.7408 63.3798 36.6387 62.5975C37.5367 61.8152 38.1213 60.7344 38.2845 59.5547H28.5137Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_3264_5823">
                  <rect width="67" height="67" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <p>Alerts on Arrival delays due to traffic.</p>
          </div>

          <div className="bustracking-3-page-div-6">
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.8934 35.3566V54.6423M86.7863 35.3566C86.7863 34.5041 86.4476 33.6865 85.8448 33.0837C85.242 32.4809 84.4245 32.1423 83.572 32.1423H77.1434V25.7137C77.1434 24.0088 76.4661 22.3736 75.2605 21.168C74.0549 19.9624 72.4198 19.2852 70.7148 19.2852H9.64341C7.93845 19.2852 6.30332 19.9624 5.09773 21.168C3.89214 22.3736 3.21484 24.0088 3.21484 25.7137V64.2852C3.21484 65.9901 3.89214 67.6252 5.09773 68.8308C6.30332 70.0364 7.93845 70.7137 9.64341 70.7137H70.7148C72.4198 70.7137 74.0549 70.0364 75.2605 68.8308C76.4661 67.6252 77.1434 65.9901 77.1434 64.2852V57.8566H83.572C84.4245 57.8566 85.242 57.5179 85.8448 56.9151C86.4476 56.3123 86.7863 55.4948 86.7863 54.6423V35.3566Z" stroke="white" strokeWidth="6.42857" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <p>
              Low power
              consumption and
              cost optimization.
            </p>

          </div>
        </div>
      </div>
      {/* Fourth page End */}

      <BottomContact />
    </div>
  )
}

export default BusTracking