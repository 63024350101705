import React from 'react';
import './styleNavbar.css';
import { Link, useNavigate } from 'react-router-dom';
function Navbar() {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    document.querySelector(".mobile-nav-toggle").classList.toggle("active");
    document.querySelector(".item-list").classList.toggle("active");
  }
  const handleClickmob = (event) => {
    event.preventDefault();
    document.querySelector(".mobile-nav-toggle-mob").classList.toggle("active");
    document.querySelector(".lower-div-mob").classList.toggle("active");
  }
  const mobNavigation = (e) => {
    if (e === '.navmob-sublist1') {
      document.getElementById('sublist1').style.display = "block";
      document.getElementById('sublist2').style.display = "none";
      document.getElementById('sublist3').style.display = "none";
    } else if (e === '.navmob-sublist2') {
      document.getElementById('sublist1').style.display = "none";
      document.getElementById('sublist2').style.display = "block";
      document.getElementById('sublist3').style.display = "none";
    } else {
      document.getElementById('sublist1').style.display = "none";
      document.getElementById('sublist2').style.display = "none";
      document.getElementById('sublist3').style.display = "block";
    }
  }
  const routeAbout = () => {
    navigate("/about-us")
  }
  const routeWebDevelopment = () => {

    navigate("services/web-development")
  }
  const routeMobileApplication = () => {
    navigate("/services/mobile-applications")
  }
  const routeinternetOfThings = (e) => {
    if (e === 1) {

      navigate("/services/internet-of-things")

    } else {
      navigate("/portfolio/internet-of-things")

    }
  }
  const routeWebxr = () => {
    // window.location.href = "/services/webxr"
    navigate("/services/webxr")
  }
  const routeChatbot = () => {
    // window.location.href = "/services/chatbot"
    navigate("/services/chatbot")
  }
  const routeUiux = () => {
    // window.location.href = "/services/ui-ux";
    navigate("/services/ui-ux")

  }
  const routeGameDevelopment = () => {
    // window.location.href = "/portfolios/game-development";
    navigate("/portfolios/game-development")
  }
  const routeElearning = () => {
    // window.location.href = "/portfolio/e-learning"
    navigate("/portfolio/e-learning")
  }
  const routeEcommerce = () => {
    // window.location.href = "/portfolios/e-commerce"
    navigate("/portfolios/e-commerce")
  }
  const routeSocialMedia = () => {
    // window.location.href = "/portfolios/social-media"
    navigate("/portfolios/social-media");
  }
  const routeBielearn = () => {
    navigate("/products/bielearn");
  }

  const routeProductDrop = () => {
    navigate("/products/drop");
  }

  const routeBusTracking = () => {
    // window.location.href = "/products/bustracking";
    navigate("/products/bustracking");
  }

  const routeSem = () => {
    // window.location.href = "/products/sem";
    navigate("/products/sem");
  }

  const routeWashmart = () => {
    // window.location.href = '/portfolios/washmart'
    navigate('/portfolios/washmart');
  }

  const routeToContactUs = () => {
    document.getElementById('blur-div').style.visibility = 'visible'

  }
  const routeHome = () => {
    window.localStorage.clear()
    window.location.href = '/'
  }


  const navigateRoute = (e) => {
    if (e === 1) {
      navigate('technologies/flutter')
    } else if (e === 2) {
      navigate('technologies/react')
    }
    else if (e === 3) {
      navigate("technologies/nodejs")
    }
    else if (e === 4) {
      navigate('technologies/MySql')
    }
    else {
      navigate('technologies/springboot')
    }
  }
  return (
    <div className='navbar' id='navbar' >
      <div className='navbar-container'>
        <a onClick={routeHome} href='/'><div className='logo'>
          <img id='logoImg' src="/images/logo.png" alt="logo" />
        </div>
        </a>
        <div className='navbar-items'>
          <ul className='item-list active' type="none">
            <li id='product'>
              <p>Technologies</p>
              <div className='product-containt'>
                <div className='product-list-container' id="container-list0">
                  <p onClick={() => navigateRoute(1)}><svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.99232 1.2946L12.0152 4.91939L8.35752 15.0331C3.19775 12.6529 0.494136 6.83617 1.99232 1.2946Z" stroke="#FF8402" strokeWidth="2" />
                  </svg> Flutter</p>
                  <p onClick={() => navigateRoute(5)}><span>Springboot</span></p>
                  <p onClick={() => navigateRoute(3)}><span>Node Js</span></p>
                  <p onClick={() => navigateRoute(2)}><span>ReactJs</span></p>
                  <p onClick={() => navigateRoute(4)}><span>My SQL <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.6521 16.3227L2.00903 11.7832L6.58965 2.05273C11.5062 4.90153 13.6582 10.9441 11.6521 16.3227Z" stroke="#FF8402" strokeWidth="2" />
                  </svg>
                  </span>
                  </p>
                </div>
              </div>
            </li>
            <li id='product'>
              <p>Products</p>
              <div className='product-containt'>
                <div className='product-list-container' id="container-list1">
                  <p onClick={routeBielearn}><svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.99232 1.2946L12.0152 4.91939L8.35752 15.0331C3.19775 12.6529 0.494136 6.83617 1.99232 1.2946Z" stroke="#FF8402" strokeWidth="2" />
                  </svg> Bielearn</p>
                  <p><Link to="https://www.washmartindia.in/" target='_blank'><span>Washmart</span></Link></p>
                  <p onClick={routeSem}><span>SEM</span></p>
                  <p onClick={routeBusTracking}><span>Bus Tracking</span></p>
                  <p onClick={routeProductDrop}><span>Drop <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.6521 16.3227L2.00903 11.7832L6.58965 2.05273C11.5062 4.90153 13.6582 10.9441 11.6521 16.3227Z" stroke="#FF8402" strokeWidth="2" />
                  </svg>
                  </span>
                  </p>
                </div>
              </div>
            </li>
            <li id='services'>
              <p>Services</p>
              <div className='services-containt'>
                <div className='services-list-container' id="container-list2">
                  <p onClick={routeWebDevelopment}><svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.99232 1.2946L12.0152 4.91939L8.35752 15.0331C3.19775 12.6529 0.494136 6.83617 1.99232 1.2946Z" stroke="#FF8402" strokeWidth="2" />
                  </svg> Web Development</p>
                  <p onClick={routeMobileApplication}><span>Mobile Application</span></p>
                  <p onClick={() => routeinternetOfThings(1)}><span>Internet of Things</span></p>
                  <p onClick={routeWebxr}><span>Webxr</span></p>
                  <p onClick={routeChatbot}><span>Chat bot</span></p>
                  <p onClick={routeUiux}><span>UI/UX <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.6521 16.3227L2.00903 11.7832L6.58965 2.05273C11.5062 4.90153 13.6582 10.9441 11.6521 16.3227Z" stroke="#FF8402" strokeWidth="2" />
                  </svg>
                  </span>
                  </p>
                </div>
              </div>
            </li>
            <li id='portfolio'>
              <p>Portfolios</p>
              <div className='portfolio-containt'>
                <div className='portfolio-list-container ' id="container-list3">
                  <p onClick={routeEcommerce}><svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.99232 1.2946L12.0152 4.91939L8.35752 15.0331C3.19775 12.6529 0.494136 6.83617 1.99232 1.2946Z" stroke="#FF8402" strokeWidth="2" />
                  </svg> Ecommerce</p>
                  <p onClick={routeGameDevelopment}><span>Game Development</span></p>
                  <p onClick={routeSocialMedia}><span>Social Media</span></p>
                  <p onClick={routeElearning}><span>E-Learning</span></p>
                  <p onClick={routeWashmart}><span>Washmart</span></p>
                  <p onClick={() => routeinternetOfThings(2)}><span>Internet of Things <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.6521 16.3227L2.00903 11.7832L6.58965 2.05273C11.5062 4.90153 13.6582 10.9441 11.6521 16.3227Z" stroke="#FF8402" strokeWidth="2" />
                  </svg>
                  </span>
                  </p>

                </div>
              </div>
            </li>
            <li id='about' onClick={() => routeAbout(1)}>About</li>
            <li id='contactNav' onClick={() => routeToContactUs()}>Contact Us</li>
          </ul>
          <div className='menu-toggle-container' id='menu-toggle-container' onClick={handleClick}>
            <div className='menu-toggle active' id='menu-toggle'>
              <a className="mobile-nav-toggle active" id="d" href="/"><span id='menu-toggle'></span></a>
            </div>
          </div>
        </div>
      </div>
      <header className='navbarMob'>
        <div className='navbarMob-container'>
          <div className='upper-nav' id='uppar-nav'>
            <a href='/'>
              <img src='/images/logoWhite.png' alt=''></img>
            </a>
            <div className='menu-toggle-container-mob' id='menu-toggle-container-mob' onClick={handleClickmob}>
              <div className='menu-toggle-mob active' id='menu-toggle-mob'>
                <a className="mobile-nav-toggle-mob" id="d" href="/"><span id='menu-toggle-mob'></span></a>
              </div>
            </div>
          </div>
          <div className='lower-div-mob'>
            <div className='nab-mob-container'>
              <div className='nav-mob-containt-container'>
                <ul className='nav-mob-contint-list' type="none">
                  <li className='navmob-list'>
                    <p onClick={() => mobNavigation(".navmob-sublist1")}>Products</p>
                    <div className='navmob-sublist1' id='sublist1'>
                      <p onClick={routeBielearn}>Bielearn</p>
                      <p>Washmart</p>
                      <p onClick={routeBusTracking}>Bus Tracking</p>
                      <p onClick={routeProductDrop}>Drop</p>
                      <p onClick={routeSem}>SEM</p>
                    </div>
                  </li>
                  <li className='navmob-list'><p onClick={() => mobNavigation(".navmob-sublist2")}>Services</p>
                    <div className='navmob-sublist2' id='sublist2'>
                      <p onClick={routeWebDevelopment}>Web Development</p>
                      <p onClick={routeMobileApplication}>Mobile Application</p>
                      <p onClick={routeinternetOfThings}>Internet of things</p>
                      <p onClick={routeWebxr}>Webxr</p>
                      <p onClick={routeChatbot}>Chatbot</p>
                      <p onClick={routeUiux}>Ui/Ux</p>
                    </div></li>
                  <li className='navmob-list'><p onClick={() => mobNavigation(".navmob-sublist3")}>Portfolios</p>
                    <div className='navmob-sublist3' id='sublist3'>
                      <p onClick={routeGameDevelopment}>Game Development</p>
                      <p onClick={routeSocialMedia}>Social Media</p>
                      <p onClick={routeinternetOfThings}>Internet of things</p>
                      <p onClick={routeElearning}>E-learning</p>
                      <p onClick={routeEcommerce}>E-commerce</p>
                    </div></li>
                  <li className='navmob-list'><p onClick={routeAbout}>About</p></li>
                  <li className='navmob-list'><p onClick={routeToContactUs}>Contact Us</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Navbar;
