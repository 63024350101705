import React from 'react';
import "./styleTestimonials.css";
function BieTestimonials() {
  return (
    <div className='testimonials'>
      <div className='testimonials-container'>
        <div className='testimonials-info'>
          <div className='testimonials-header'>
            <h1>Testimonials</h1>
            <hr />
          </div>
          <div className='testimonials-rating'>
            <div className='testimonials-rating-left'>
              <div className='testimonials-rating-left-first  f1'>
                <div className='rating-header'>
                  <div className='rating-profile'>
                    <img src='/images/admin_female_avatar.png' alt='/'  id="admin_female_avatar"/>
                  </div>
                  <div className='rating-per-info'>
                    <h3>Bugade Sir Classes</h3>
                    <p>Admin</p>
                    <img src='/images/Rating.png' alt='/' />
                  </div>
                </div>
                <div className='rating-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
              </div>
              <div className='testimonials-rating-left-first f2'>
                <div className='rating-header r-f2'>
                  <div className='rating-profile'>
                    <img src='/images/student_female_avatar.png' alt='/' />
                  </div>
                  <div className='rating-per-info'>
                    <h3>Core2Web Technologies</h3>
                    <p>Student</p>
                    <img src='/images/Rating.png' alt='/' />
                  </div>
                </div>
                <div className='rating-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  </div>
              </div>
            </div>
            <div className='testimonials-rating-right'>
              <div className='testimonials-rating-left-first f3'>
                <div className='rating-header r-f3'>
                  <div className='rating-profile'>
                    <img src='/images/admin_male_avatar.png' alt='/' />
                  </div>
                  <div className='rating-per-info'>
                    <h3>Sachin Sir's 11th, 12th</h3>
                    <p>Admin</p>
                    <img src='/images/Rating.png' alt='/' />
                  </div>
                </div>
                <div className='rating-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  </div>
              </div>
              <div className='testimonials-rating-left-first f2'>
                <div className='rating-header r-f2'>
                  <div className='rating-profile'>
                    <img src='/images/student_male_avatar.png' alt='/' />
                  </div>
                  <div className='rating-per-info'>
                    <h3>DnyanSagar Coaching Center</h3>
                    <p>Student</p>
                    <img src='/images/Rating.png' alt='/' />
                  </div>
                </div>
                <div className='rating-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
              </div>
            </div>
          </div>
        </div>
        <div className='see-more'>
          <p>see more</p>
          <svg className='see-more-btn' width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="30" fill="#6125A9" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M34.0235 23.711C34.1106 23.6237 34.214 23.5544 34.3279 23.5071C34.4418 23.4599 34.5639 23.4355 34.6872 23.4355C34.8105 23.4355 34.9326 23.4599 35.0465 23.5071C35.1604 23.5544 35.2639 23.6237 35.351 23.711L40.976 29.336C41.0633 29.4231 41.1325 29.5265 41.1798 29.6404C41.2271 29.7543 41.2514 29.8764 41.2514 29.9997C41.2514 30.123 41.2271 30.2451 41.1798 30.359C41.1325 30.4729 41.0633 30.5764 40.976 30.6635L35.351 36.2885C35.1749 36.4645 34.9362 36.5634 34.6872 36.5634C34.4383 36.5634 34.1995 36.4645 34.0235 36.2885C33.8474 36.1124 33.7485 35.8737 33.7485 35.6247C33.7485 35.3758 33.8474 35.137 34.0235 34.961L38.9866 29.9997L34.0235 25.0385C33.9362 24.9514 33.8669 24.8479 33.8196 24.734C33.7724 24.6201 33.748 24.498 33.748 24.3747C33.748 24.2514 33.7724 24.1293 33.8196 24.0154C33.8669 23.9015 33.9362 23.7981 34.0235 23.711Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 30C18.75 29.7514 18.8488 29.5129 19.0246 29.3371C19.2004 29.1613 19.4389 29.0625 19.6875 29.0625H39.375C39.6236 29.0625 39.8621 29.1613 40.0379 29.3371C40.2137 29.5129 40.3125 29.7514 40.3125 30C40.3125 30.2486 40.2137 30.4871 40.0379 30.6629C39.8621 30.8387 39.6236 30.9375 39.375 30.9375H19.6875C19.4389 30.9375 19.2004 30.8387 19.0246 30.6629C18.8488 30.4871 18.75 30.2486 18.75 30Z" fill="white" />
          </svg>
        </div>
        <div className='testimonials-vectors'>
          <svg width="88" height="75" viewBox="0 0 88 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.02886 72.5L44 5L82.9711 72.5H5.02886Z" stroke="url(#paint0_linear_1573_17027)" strokeWidth="5" />
            <defs>
              <linearGradient id="paint0_linear_1573_17027" x1="44" y1="0" x2="44" y2="100" gradientUnits="userSpaceOnUse">
                <stop stop-color="#837EAB" />
                <stop offset="1" stop-color="#4D487E" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default BieTestimonials
