import React from "react";
import "../About/About.css";
function About() {
  return (
    <div className="about-main-container slide">
      <div className="about-sub-container">
        <div className="sub-about-div-one">
          <div className="about-div-one-left-img">
          <img src="/images/company2.jpg" alt="/" />
          </div>
          <div className="about-div-one-right">
            <div className="About-Us">
              <h1 id="about-us-heading">ABOUT US</h1>
              <p>
                Biencaps is running with the rock-solid foundation of our great leading team of directors. Their visions, planning
                and passion for work make Biencaps the best company.
              </p>
            </div>
            <div className="about-image-second">
              <img src="/images/company4.jpg" alt="/" />
            </div>
          </div>
        </div>

        <svg width="41" height="57" viewBox="0 0 41 57" fill="none" xmlns="http://www.w3.org/2000/svg" id="about-svg-2">
          <path d="M28.5977 0L0.000218118 28.3257L28.1097 56.7048C43.9048 41.0597 44.1233 15.6746 28.5977 0Z" fill="#FF8402"/>
        </svg>
  
        <svg width="26" height="36" viewBox="0 0 26 36" fill="none" xmlns="http://www.w3.org/2000/svg" id="about-svg-1">
          <path d="M20.9949 33.1596L2.06888 21.1992L14.1332 2.10877C23.8937 9.16369 26.8552 22.5053 20.9949 33.1596Z" stroke="#FF8402" strokeWidth="3"/>
        </svg>

        <svg width="42" height="50" viewBox="0 0 42 50" fill="none" xmlns="http://www.w3.org/2000/svg" id="about-svg-3">
          <path d="M32.2148 49.498L41.2782 9.43835L1.52429 0.444199C-3.48165 22.5703 10.2576 44.5303 32.2148 49.498Z" fill="#E9E5D9"/>
        </svg>

        <svg width="36" height="49" viewBox="0 0 36 49" fill="none" xmlns="http://www.w3.org/2000/svg" id="about-svg-4">
          <path d="M10.7812 49L35.2048 24.5765L10.9677 0.339375C-2.52212 13.8292 -2.60558 35.6132 10.7812 49Z" fill="#FF8402"/>
        </svg>  
      </div>
    </div>
  );
}

export default About;
