import React, { useEffect } from 'react';
import '../../pages/product/Sem.css';
import BottomContact from '../../components/BottomContact/BottomContact';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';
function Sem() {
  useEffect(() => {
    const ele = document.getElementById("home-sem");
    if (ele.scrollTop < ele.clientHeight) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "none";
      document.querySelector(".mobile-nav-toggle").classList.add("active");
      document.querySelector(".item-list").classList.add("active");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";

    }
  }, [])
  const handleScroll = () => {
    const ele = document.getElementById("home-sem");
    if (ele.scrollTop < ele.clientHeight) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "none";
      document.querySelector(".mobile-nav-toggle").classList.add("active");
      document.querySelector(".item-list").classList.add("active");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";

    } else if (ele.scrollTop < (ele.clientHeight * 2) - 2) {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "/images/logoWhite.png";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";

    } else if (ele.scrollTop < (ele.clientHeight * 3) - 2) {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";
      document.querySelector(".item-list").classList.remove("active");
      document.querySelector(".mobile-nav-toggle").classList.remove("active");

    } else if (ele.scrollTop > ele.clientHeight * 3.5) {
      document.getElementById("navbar").style.color = "white";
      document.getElementById("logoImg").src = "/images/logoWhite.png";
      document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
      document.getElementById("menu-toggle-container").style.display = "flex";
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
      document.querySelector(".item-list").classList.remove("active");
      document.getElementById("container-list0").style.color = "white";
      document.getElementById("container-list1").style.color = "white";
      document.getElementById("container-list2").style.color = "white";
      document.getElementById("container-list3").style.color = "white";

    } else {
      document.getElementById("navbar").style.color = "black";
      document.getElementById("logoImg").src = "/images/logo.png";
      document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
      document.getElementById("container-list0").style.color = "black";
      document.getElementById("container-list1").style.color = "black";
      document.getElementById("container-list2").style.color = "black";
      document.getElementById("container-list3").style.color = "black";
      document.querySelector(".item-list").classList.remove("active");
      document.querySelector(".mobile-nav-toggle").classList.remove("active");
    }
  }

  const routeToContactUs = () => {
    document.getElementById('blur-div').style.visibility = 'visible'
  }

  return (
    <div className='drop-main-container' style={{ height: "100vh", width: "100%", overflow: "auto" }} id="home-sem" onScroll={handleScroll}>
      {/*Bustracking  First page  */}
      <div className="Drop-first-page">
        <div className="Drop-first-page-sub-div">
          <svg width="25" height="35" viewBox="0 0 25 35" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-1">
            <path d="M20.3246 32.0608L2.06956 20.5244L13.7065 2.11013C23.0983 8.92931 25.9511 21.7806 20.3246 32.0608Z" stroke="#FF8402" strokeWidth="3" />
          </svg>
          <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-2">
            <path d="M31.5156 47.5254L40.2967 8.71345L1.78099 -0.000564967C-3.06902 21.4364 10.2423 42.7124 31.5156 47.5254Z" fill="#E9E5D9" />
          </svg>

          <svg width="36" height="29" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-3">
            <path d="M1.83624 26.6688L8.22156 1.82641L33.2767 8.26642C29.1029 21.4613 15.533 29.4193 1.83624 26.6688Z" stroke="#FF8402" strokeWidth="3" />
          </svg>

          <svg width="36" height="29" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-4">
            <path d="M1.83624 26.6688L8.22156 1.82641L33.2767 8.26642C29.1029 21.4613 15.533 29.4193 1.83624 26.6688Z" stroke="#272D2F" strokeWidth="3" />
          </svg>

          <svg width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-5">
            <path d="M0 26.5L32.7491 33.3813L39.5779 0.882174C21.4896 -2.91856 3.77172 8.54981 0 26.5Z" fill="#FF8402" />
          </svg>

          <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg" id="drop-vector-6">
            <path d="M31.5156 47.5234L40.2967 8.71149L1.78099 -0.00251809C-3.06902 21.4344 10.2423 42.7104 31.5156 47.5234Z" fill="#E9E5D9" />
          </svg>

          <div className="Drop-first-page-sub-container">
            <div className="Drop-first-page-text-content">
              <div className="Drop-heading">
                <h1 id="Drop">SEM</h1>
              </div>
              <div className="Drop-paragraph">
                <p>
                  SEM Smart Electricity Management is definitive & durable solution for electricity consumption, monitoring, and control. It consists of a device installed on the electricity distribution system & an android application to keep track of electronic devices. For electricity conservation, the app gives notifications on excess unit consumptions, smart switches to operate electronic devices. Users can limit electricity usage by setting a threshold value.
                </p>
              </div>
              <div className="Drop-enquire-now Sem-enquire-now">
                <div className='drop-enquire-now-btn connect' id='connectBtn' onClick={() => routeToContactUs()}><p>Enquire Now</p></div>
              </div>
            </div>
            <div className="Drop-first-image-content">
              <img src="/images/ProductSEM.png" alt="/" id="Drop-image" />
            </div>
          </div>
        </div>
      </div>
      {/*Bustracking First page end */}

      {/* Bus tracking Second page Start */}
      <div className='Bustracking-second-page Sem-Second-page'>

        <div className="product-in-glanace">
          <div className="">
            <h1>Product in a glance</h1>
          </div>
          <div>
            <svg width="146" height="21" viewBox="0 0 146 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10.5" cy="10.5" r="10.5" fill="#FF8402" />
              <circle cx="52.5" cy="11.5" r="7.5" fill="white" fill-opacity="0.37" />
              <circle cx="95.5" cy="11.5" r="7.5" fill="white" fill-opacity="0.37" />
              <circle cx="138.5" cy="11.5" r="7.5" fill="white" fill-opacity="0.37" />
            </svg>
          </div>
        </div>

        <div className="Drop-secondpage-cards-sem">
          <div className="Drop-secondpage-cards-scroller drop-product">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              mousewheel={true}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
                1530: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
                1700: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                }

              }}
              navigation={false}
              modules={[Autoplay]}
              className="mySwiper"
            ><SwiperSlide>
                <div className="Drop-second-page-card-1 ">
                  <img src="/images/DropSecond-page-card-1-img.png" alt="/" />
                  <h1>RT-DAS</h1>
                  <p>Provides real-time monitoring and data acquisition for up-to-date information on electricity consumption.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Drop-second-page-card-1 " id="Bustracking-second-page-card-2">
                  <img src="/images/BustrackingSecond-page-2-image.png" alt="/" />
                  <h1>Data Analytics</h1>
                  <p>Involves analyzing data to derive insights and identify trends related to electricity usage.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Drop-second-page-card-1 " id="Bustracking-second-page-card-3">
                  <img src="/images/DropSecond-page-card-3-img.png" alt="/" />
                  <h1>Smart Analytics</h1>
                  <p>Utilizes intelligent analysis for efficient decision-making in managing and controlling electricity consumption.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Drop-second-page-card-1 " id="Bustracking-second-page-card-4">
                  <img src="/images/DropSecond-page-card-4-img.png" alt="/" />
                  <h1>Cost Efficiency</h1>
                  <p>Focuses on effective resource utilization for economic operation, ensuring that the solution is affordable and efficient.  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      {/* Bus tracking Second page End */}


      {/* Third page start */}
      <div className="Drop-third-page">
        <div className="Drop-third-page-sub-div">
          <div className="Drop-third-page-left-content">
            <div className="Drop-how-it-work">
              <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg" id="how-it-work-circle">
                <circle cx="50.5" cy="50.5" r="50.5" fill="#E9E5D9" />
              </svg>
              <h1>How it work</h1>
              <svg width="25" height="35" viewBox="0 0 25 35" fill="none" xmlns="http://www.w3.org/2000/svg" id="how-it-work-vector">
                <path d="M20.3246 32.0608L2.06956 20.5244L13.7065 2.11013C23.0983 8.92931 25.9511 21.7806 20.3246 32.0608Z" stroke="#FF8402" strokeWidth="3" />
              </svg>
            </div>

            <div className="drop-third-page-left-content">
              <div className="step-div">
                <div className="step01 step02">
                  <h1>Step 02</h1>
                </div>
                <img src="/images/Sem-step-02.png" id="Step-left-image" alt="/" />
              </div>
              <div className="third-page-left-paragaph" id="third-page-left-paragaph-bustraking">
                <h1>Mobile App Set-Up</h1>
                <p>Download the Drop mobile application from play-store, enter the unique customer identification key to communicate with the installed device. Once the setup is complete, Drop starts gathering & logging data over the cloud.</p>
              </div>
            </div>
          </div>
          <div className="Drop-third-page-middle-content">
            <svg width="62" height="850" viewBox="0 0 62 850" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="32.5" y1="70.5" x2="32.5" y2="388.5" stroke="black" stroke-opacity="0.5" strokeWidth="5" stroke-linecap="round" stroke-dasharray="20 20" />
              <line x1="32.5" y1="472.5" x2="32.5" y2="775.5" stroke="black" stroke-opacity="0.5" strokeWidth="5" stroke-linecap="round" stroke-dasharray="20 20" />
              <circle cx="31" cy="31" r="29.5" stroke="#0065B8" strokeWidth="3" />
              <circle cx="31" cy="31" r="15" fill="#0065B8" />
              <circle cx="31" cy="819" r="29.5" stroke="#FF8402" strokeWidth="3" />
              <circle cx="31" cy="819" r="15" fill="#FF8402" />
              <circle cx="31" cy="430" r="29.5" stroke="#008F88" strokeWidth="3" />
              <circle cx="31" cy="430" r="15" fill="#008F88" />
            </svg>

          </div>
          <div className="Drop-third-page-right-content">
            <div className="Third-right-div-1">
              <img src="/images/step01-image.PNG" id="step01-image" alt="/" />
              <div className="step01">
                <h1>Step 01</h1>
              </div>
              <div className="step-1-paragraph">

                <h1>Device Installation</h1>
                <p>
                  Install the device on the water tank for monitoring water level and connectivity with the water pump. This device comes with a unique customer identification key that helps access it through the mobile application.
                </p>
              </div>
            </div>

            <div className="Third-right-div-1 Third-right-div-2">

              <img src="/images/Sem-step-03.png" id="step03-image" alt="/" />
              <div className="step-1-paragraph step-3-paragraph ">
                <div className="step01 step03">
                  <h1>Step 03</h1>
                </div>
                <h1>Electricity Monitoring & Controlling</h1>
                <p>Setup monthly/weekly electricity consumption limits. Monitor over electricity consumption of each electronic device. Monitor utilization through graphical & tabular data representation. Get alerts and notifications on excessive usage</p>
              </div>
            </div>

          </div>
        </div>

        {/* Drop-third-page-sub-div for mobile*/}
        <div className="Drop-third-page-sub-div-mobile">
          <img src="/images/step-line.png" alt="/" id="step-line" />
          <div className="Drop-third-page-sub-div-mobile-content">
            <div className="mobile-step01-div">
              <div className="step01-mobile"><p className="step01-mobile-head">Step 01</p></div>
              <h3 id="step01-h3">device installation</h3>
              <p style={{ textAlign: 'left' }}>Install the device on the water tank for monitoring water level and connectivity with the water pump. This device comes with a unique customer identification key that helps access it through the mobile application.</p>
            </div>
            <div className="mobile-step01-div">
              <div className="step01-mobile" style={{ border: "1px solid #008F88" }}><p className="step01-mobile-head" style={{ backgroundColor: "#008F88" }}>Step 02</p></div>
              <h3 id="step01-h3">Mobile App Set-Up</h3>
              <p style={{ textAlign: 'left' }}>Install the device on the water tank for monitoring water level and connectivity with the water pump. This device comes with a unique customer identification key that helps access it through the mobile application.</p>
            </div>
            <div className="mobile-step01-div">
              <div className="step01-mobile" style={{ border: "1px solid #FF8402" }}><p className="step01-mobile-head" style={{ backgroundColor: "#FF8402" }}>Step 03</p></div>
              <h3 id="step01-h3">Data Monitoring & Controlling</h3>
              <p style={{ textAlign: 'left' }}>Install the device on the water tank for monitoring water level and connectivity with the water pump. This device comes with a unique customer identification key that helps access it through the mobile application.</p>
            </div>
          </div>
        </div>


      </div>
      {/* Third page End */}


      {/* Fourth Page */}
      <div className='Sem-fourth-page'>
        <div className='sem-fourth-page-inner-div'>
          <div className='Sem-4-page-div-1'>
            <p className='step-para'>
              Integrated mobile
              application for monitoring
              and notifications.
            </p>
          </div>

          <div className='Sem-4-page-div-2'>
            <p>
              Real-time electricity
              consumption & controlling
              using sensors
            </p>
          </div>

          <div className='Sem-4-page-div-3'>
            <p id="Lets">Lets not forget the</p>
            <p id="Sem-p1">Features</p>
          </div>

          <div className='Sem-4-page-div-4'>
            <p>Statistical representation
              of data in a graphical
              manner
            </p>
          </div>

          <div className='Sem-4-page-div-5'>
            <p>Storage of data is done
              through a secured cloud with
              minimum latency.
            </p>
          </div>

          <div className='Sem-4-page-div-6'>
            <p>Multiple wireless
              connectivity options, low
              power consumption, and
              cost optimization.
            </p>
          </div>
        </div>

      </div>
      {/* Fourth page End */}
      <BottomContact />
    </div>
  )
}

export default Sem