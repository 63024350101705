import React, {useState, useEffect} from 'react'
import TechMySQL from './website/components/technologies/TechMySQL';
import TechSpringboot from './website/components/technologies/TechSpringboot';
import TechReact from './website/components/technologies/TechReact';
import WebDevelopmentClick from './website/pages/Service/WebDevelopmentClick';
import { Route, Routes } from 'react-router-dom';
import HomeNavigate from './website/HomeNavigate'
import TechNodeJs from './website/components/technologies/TechNodeJs';
import TechReactJs from './website/components/technologies/TechReactJs';
import MobileApplication from './website/pages/Service/MobileApplication';
import Iot from './website/pages/Portfolio/PortFolioContainer/PortFolioComponents/Iot';
import E_Learning from './website/pages/Portfolio/PortFolioContainer/PortFolioComponents/E_Learning';
import Game_Wow from './website/pages/Portfolio/PortFolioContainer/PortFolioComponents/Game_Wow';
import SocialMedia from './website/pages/Portfolio/PortFolioContainer/PortFolioComponents/SocialMedia';
import ServieIotClick from './website/pages/Service/ServieIotClick';
import ServiceChatbotClick from './website/pages/Service/ServiceChatbotClick';
import ServiceWebxrClick from './website/pages/Service/ServiceWebxrClick';
import ServiceUiUxClick from './website/pages/Service/ServiceUiUxClick';

import AboutClick from './website/pages/About/AboutClick';
import Bielearn from './website/pages/product/bielearn/Bielearn';
import Drop from './website/pages/product/Drop';
import BusTracking from './website/pages/product/BusTracking';
import Sem from './website/pages/product/Sem';
import Washmart from './website/pages/Portfolio/PortFolioContainer/PortFolioComponents/Washmart';
import E_Commerce from './website/pages/Portfolio/PortFolioContainer/PortFolioComponents/E_Commerce';
import PageNotFound from './website/components/PageHandler/PageNotFound';
import PageNotFoundDay from './website/components/PageHandler/PageNotFoundDay';
function RoutingComponent() {
  const [pnp, setPnp] = useState();
  useEffect(()=>{

    window.addEventListener("orientationchange", function() {
      // Announce the new orientation number
      alert(window.orientation);
    }, false);

    const time = new Date();
    if(time.getHours()>=6 && time.getHours()<=18){
      setPnp(true);
     
    }else{
      setPnp(false)
      
    }
  },[])
  return (
    

    <Routes>{
             pnp? <Route path="*" Component={PageNotFoundDay}/>:<Route path="*" Component={PageNotFound}/>
          }
          <Route path='/' Component={HomeNavigate} />
          <Route path='services/web-development' Component={WebDevelopmentClick} />
          <Route path='/technologies/flutter' Component={TechReactJs} />
          <Route path='/technologies/react' Component={TechReact} />
          <Route path='/technologies/nodejs' Component={TechNodeJs} />
          <Route path='/technologies/MySql' Component={TechMySQL} />
          <Route path='/services/mobile-applications' Component={MobileApplication} />
          <Route path='/portfolio/internet-of-things' Component={Iot} />
          <Route path='/portfolio/e-learning' Component={E_Learning} />
          <Route path='/portfolios/game-development' Component={Game_Wow} />
          <Route path='/portfolios/social-media' Component={SocialMedia} />
          <Route path='/technologies/springboot' Component={TechSpringboot} />
          <Route path='/services/internet-of-things' Component={ServieIotClick} />
          <Route path='/services/chatbot' Component={ServiceChatbotClick} />
          <Route path='/services/webxr' Component={ServiceWebxrClick} />
          <Route path='/services/ui-ux' Component={ServiceUiUxClick} />
          <Route path='/about-us' Component={AboutClick} />
          <Route path="/products/bielearn" Component={Bielearn}/>
          <Route path='/products/drop' Component={Drop} />
          <Route path='/products/bustracking' Component={BusTracking} />
          <Route path='/products/sem' Component={Sem} />
          <Route path='/portfolios/washmart' Component={Washmart} />
          <Route path='/portfolios/e-commerce' Component={E_Commerce} /> 
          <Route path='/portfolio/e-learning' Component={E_Learning} />
        </Routes>
  )
}

export default RoutingComponent
