import React, { useEffect } from "react";
import "../Service/ServiceIotClick.css";
function ServieIotClick() {
  useEffect(() => {
    document.getElementById("navbar").style.color = "white";
    document.getElementById("logoImg").src = "/images/logoWhite.png";
    document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
    document.getElementById("menu-toggle-container").style.display = "none";
    document.querySelector(".mobile-nav-toggle").classList.add("active");
    document.querySelector(".item-list").classList.add("active");
    document.getElementById("container-list0").style.color = "white";
    document.getElementById("container-list1").style.color = "white";
    document.getElementById("container-list2").style.color = "white";
    document.getElementById("container-list3").style.color = "white";
  })

  return (
    <div className="Mobile-application-main-container">
      <div className="Mobile-application-firstpage-page">

        <img src="/images/Vectorcolor.png" alt="/" id="vector-color" />
        <img src="/images/Vectorempty.png" alt="/" id="Vectorempty" />
        <img src="/images/Vectorempty.png" alt="/" id="Vectoremptyrotate" />
        <img src="/images/white-Vector.png" alt="/" id="white-Vector" />
        <img src="/images/VectorsmallWhite.png" alt="/" id="VectorsmallWhite" />
        <img src="/images/threeDot.png" alt="/" id="service-threeDot" />


        <div className="Mobile-application-first-page-content-container">
          <div className="left-container-Mobile-image">
            <img src="/images/ServiceIot-first-page-image.png" alt="" id="ServiceIot-first-page-image" />
          </div>

          <div className="right-container-Mobile-application  right-container-iot">
            <div className="right-container-Mobile-application-heading right-container-iot-heading">
              Internet Of Things
            </div>
            <div className="right-container-Mobile-application-para right-container-iot-para">
              <p>
                Biencaps unlocks the value of connected devices and data analytics to empower Industrial Internet of Things solution providers as well as different businesses. Our smart IoT solutions empower such industries as oil and gas, agriculture, transportation, logistics and more. Our skills and solutions help enterprise adopters to increase operational efficiency, worker safety, quality control, and corporate social responsibility.
              </p>
            </div>
            <div className="cards">
              <div className="card-content">
                <h1>+9</h1>
                <h3>OUR CREATION</h3>
              </div>
              <div className="card-content">
                <h1 id="card-content-h1">+19</h1>
                <h3>OUR CONNECTION</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SECOND PAGE */}
      {/* <div className="mobile-application-second-apge Iot-second-page">

      <img src="/images/Vectorcolor.png" id="vector-color"/>
      <img src="/images/Vectorempty.png" id="Vectorempty"/>
      <img src="/images/Vectorempty.png" id="Vectoremptyrotate"/>
      <img src="/images/white-Vector.png"id="white-Vector" />
      
      <div className="mobile-application-second-apge-subcontainer">
        
        <div className="mobile-application-cms">
          <div className="cms-paraghraph mobile-cms-para-1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
            quasi totam corrupti officiis commodi deleniti, ipsum porro
          </div>

          <div className="cms-card mobile-cms-card-1" onMouseEnter={(e) => onMouseEnterFuntion({name: ".mobile-cms-para-1", card: ".mobile-cms-card-1",})} onMouseLeave={() =>onMouseLeaveFuntion({name: ".mobile-cms-para-1",card: ".mobile-cms-card-1",})}>
          <div className="cms-logo">
            <img src="/images/cms-logo.png" alt="/" />
          </div>
          <div className="cms-heading">
            <h1>cms</h1>
          </div>
        </div>
        </div>


        <div className="mobile-application-cms">
        <div className="cms-paraghraph cms-mobile-para-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
            quasi totam corrupti officiis commodi deleniti, ipsum porro
          </div>
          <div className="cms-card mobile-cms-card-2" onMouseEnter={(e) => onMouseEnterFuntion({name: ".cms-mobile-para-2", card: ".mobile-cms-card-2",})} onMouseLeave={() =>onMouseLeaveFuntion({name: ".cms-mobile-para-2",card: ".mobile-cms-card-2",})}>
          <div className="cms-logo">
            <img src="/images/webxr-logo.png" alt="/" />
          </div>
          <div className="cms-heading">
            <h1>webxr</h1>
          </div>
          </div>
        </div>


        <div className="mobile-application-cms">
        <div className="cms-paraghraph cms-mobile-para-3">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
            quasi totam corrupti officiis commodi deleniti, ipsum porro
          </div>
          <div className="cms-card mobile-cms-card-3" onMouseEnter={(e) => onMouseEnterFuntion({name: ".cms-mobile-para-3", card: ".mobile-cms-card-3",})} onMouseLeave={() =>onMouseLeaveFuntion({name: ".cms-mobile-para-3",card: ".mobile-cms-card-3",})}>
          <div className="cms-logo">
            <img src="/images/seo-logo.png" alt="/" />
          </div>
          <div className="cms-heading">
            <h1>seo</h1>
          </div>
        </div>
        </div>


        <div className="mobile-application-cms">
        <div className="cms-paraghraph cms-mobile-para-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
            quasi totam corrupti officiis commodi deleniti, ipsum porro
          </div>
          
          <div className="cms-card mobile-cms-card-4" onMouseEnter={(e) => onMouseEnterFuntion({name: ".cms-mobile-para-4", card: ".mobile-cms-card-4",})} onMouseLeave={() =>onMouseLeaveFuntion({name: ".cms-mobile-para-4",card: ".mobile-cms-card-4",})}>
          <div className="cms-logo">
            <img src="/images/cms-logo.png" alt="/" />
          </div>
          <div className="cms-heading">
            <h1>cms</h1>
          </div>
        </div>
      </div>


        <div className="mobile-application-cms">
        <div className="cms-paraghraph cms-mobile-para-5">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
            quasi totam corrupti officiis commodi deleniti, ipsum porro
          </div>

          <div className="cms-card mobile-cms-card-5" onMouseEnter={(e) => onMouseEnterFuntion({name: ".cms-mobile-para-5", card: ".mobile-cms-card-5",})} onMouseLeave={() =>onMouseLeaveFuntion({name: ".cms-mobile-para-5",card: ".mobile-cms-card-5",})}>
          <div className="cms-logo">
            <img src="/images/cms-logo.png" alt="/" />
          </div>
          <div className="cms-heading">
            <h1>cms</h1>
          </div>
        </div>
        </div>
      </div>
    </div> */}
    </div>
  );
}

export default ServieIotClick;
