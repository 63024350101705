import React from 'react';
import "./styleBieSolutions.css";
function BieSolutions() {
  return (
    <div className='bie-Solutions'>
      <div className='bie-Solutions-container'>
        <div className='bie-solutions-header'>
          <h1>Our Solution</h1>
        </div>
        <div className='bie-solutions-info-container'>
          <div className='bie-solutions-info-upper'>
            <div className='container-upper container-upper-1'>
              <img src='/images/mobile_phone 1.png' alt='/' />
              <p>An App Management Console for teachers to stream lectures via a secure platform along with the means to conduct activities within the institute.</p>
            </div>
            <div className='container-upper container-upper-2'>
              <img src='/images/analysis.png' alt='/' />
              <p>Register with us & get a step closer to get your institute online. we will contact you within 1-2 business days.</p>
            </div>
          </div>
          <div className='bie-solutions-info-lower'>
            <div className='container-lower container-lower-1'>
              <img src='/images/mobile_testing.png' alt='/' />
              <p>A Dedicated Mobile Application available on both Android & iOS platform for the students to watch lectures & participate in the institutional activities.</p>
            </div>
            <div className='container-lower container-lower-2'>
              <img src='/images/video_call (1) 1.png' alt='/' />
              <p>An Institute Management Console for Admin to manage, watch & control, overall activities within an institution. Forget about the tons of paperwork and be the change.</p>
            </div>
          </div>
          <img className='timeline' src='/images/Timeline.png' alt='/' />
          <img src="/images/Timeline-mobile.png" alt="/" id="Timeline-mobile" />
        </div>
        <div className='bieSolutions-vectors'>
          <svg className='sosvg1' width="40" height="89" viewBox="0 0 40 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-17.5 2.5H10H37.5V86.5H-17.5V2.5Z" stroke="url(#paint0_linear_1573_17029)" strokeWidth="5" />
            <defs>
              <linearGradient id="paint0_linear_1573_17029" x1="10" y1="0" x2="10" y2="89" gradientUnits="userSpaceOnUse">
                <stop stop-color="#837EAB" />
                <stop offset="1" stop-color="#4D487E" />
              </linearGradient>
            </defs>
          </svg>
          <svg className='sosvg2' width="88" height="75" viewBox="0 0 88 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.02886 72.5L44 5L82.9711 72.5H5.02886Z" stroke="url(#paint0_linear_1573_17026)" strokeWidth="5" />
            <defs>
              <linearGradient id="paint0_linear_1573_17026" x1="44" y1="0" x2="44" y2="100" gradientUnits="userSpaceOnUse">
                <stop stop-color="#837EAB" />
                <stop offset="1" stop-color="#4D487E" />
              </linearGradient>
            </defs>
          </svg>
          <svg className='sosvg3' width="70" height="100" viewBox="0 0 70 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="47.5" stroke="url(#paint0_linear_1573_17028)" strokeWidth="5" />
            <defs>
              <linearGradient id="paint0_linear_1573_17028" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
                <stop stop-color="#837EAB" />
                <stop offset="1" stop-color="#4D487E" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default BieSolutions;
