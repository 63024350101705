import React, { useEffect } from "react";
import "../Service/WebDevelopmentClick.css";

function WebDevelopmentClick() {
  useEffect(() => {
    document.getElementById("navbar").style.color = "white";
    document.getElementById("logoImg").src = "/images/logoWhite.png";
    document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
    document.getElementById("menu-toggle-container").style.display = "none";
    document.querySelector(".mobile-nav-toggle").classList.add("active");
    document.querySelector(".item-list").classList.add("active");
    document.getElementById("container-list0").style.color = "white";
    document.getElementById("container-list1").style.color = "white";
    document.getElementById("container-list2").style.color = "white";
    document.getElementById("container-list3").style.color = "white";
  })
  return (
    <div className="Mobile-application-main-container" id="web-development-page" style={{ height: "100vh", width: "100%", overflow: "auto" }}>
      <div className="Mobile-application-firstpage-page">
        <img src="/images/Vectorcolor.png" alt="/" id="vector-color" />
        <img src="/images/Vectorempty.png" alt="/" id="Vectorempty" />
        <img src="/images/Vectorempty.png" alt="/" id="Vectoremptyrotate" />
        <img src="/images/white-Vector.png" alt="/" id="white-Vector" />
        <img src="/images/threeDot.png" alt="/" id="service-threeDot" />


        <div className="Mobile-application-first-page-content-container UiUx-first-page-content-container">
          <div className="left-container-Mobile-image">
            <img src="/images/web-service-logo.png" alt="/" id="Web-first-page-image" />
          </div>

          <div className="right-container-Mobile-application">
            <div className="right-container-Mobile-application-heading">
              Web Development
            </div>
            <div className="right-container-Mobile-application-para">
              <p>
                A Good website is Not only Rich UI but also the interaction simplicity & Responsiveness, we will make to sure to deliver the best product for you. Good web design is more than an About Us and a Contact page. Its how you communicate. Its how you show off your products and capabilities and most importantly, it's how you convert leads.
              </p>
            </div>
            <div className="cards">
              <div className="card-content">
                <h1>+9</h1>
                <h3>OUR CREATION</h3>
              </div>
              <div className="card-content">
                <h1 id="card-content-h1">+19</h1>
                <h3>OUR CONNECTION</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default WebDevelopmentClick;



