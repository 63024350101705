import React from 'react';
import "./styleTechnologies.css";
import flutterLogo from '../../images/Group 990flutterLogo.png';
import reactLogo from "../../images/GroupreactjsLogo.png";
import nodejsLogo from "../../images/Group 993nodejsLogo.png";
import mysqlLogo from "../../images/Group 989mysqlLogo.png";
import springBootLogo from "../../images/Group 992springbootLogo.png";
import { useNavigate } from 'react-router-dom';

function Technologies() {
    const navigate = useNavigate();

    const handleView = (e) => {
        if (e === 1) {
            document.getElementById("flutterImg").src = "/images/flutterColor.png";
        } else if (e === 2) {
            document.getElementById("reactImg").src = "/images/reactColor.png";
        } else if (e === 3) {
            document.getElementById("nodeImg").src = "/images/NodejsColor.png";
        } else if (e === 4) {
            document.getElementById("mysqlImg").src = "/images/mysqlColor.png";
        } else {
            document.getElementById("springImg").src = "/images/springColor.png";
        }

    }
    const handleUnview = (e) => {
        if (e === 1) {
            document.getElementById("flutterImg").src = flutterLogo;
        } else if (e === 2) {
            document.getElementById("reactImg").src = reactLogo;
        } else if (e === 3) {
            document.getElementById("nodeImg").src = nodejsLogo;
        } else if (e === 4) {
            document.getElementById("mysqlImg").src = mysqlLogo;
        } else {
            document.getElementById("springImg").src = springBootLogo;
        }
    }
    const navigateRoute = (e) => {
        if (e === 1) {
            navigate('technologies/flutter')
        } else if (e === 2) {
            navigate('technologies/react')
        }
        else if (e === 3) {
            navigate("technologies/nodejs")
        }
        else if (e === 4) {
            navigate('technologies/MySql')
        }
        else {
            navigate('technologies/springboot')
        }
    }
    return (
        <div className="technologies slide">
            <div className='technologies-container'>
                <div className='technologies-text-container'>
                    <h1>TECHNOLOGIES</h1>
                    <h3>we are expert in</h3>
                    <p>Flutter, React, Node.js, MySQL & Springboot<br />—crafting dynamic UIs and scalable solutions for web and mobile</p>
                </div>
                <div className='technologies-info'>
                    <div className='technologies-info-container'>

                        <div onClick={() => navigateRoute(1)}>
                            <div className='tech-logo-container' onMouseEnter={() => handleView(1)} onMouseLeave={() => handleUnview(1)}>
                                <img id='flutterImg' style={{ width: "52%" }} src={flutterLogo} alt='/' />
                            </div>
                        </div>

                        <div onClick={() => navigateRoute(2)}>
                            <div className='tech-logo-container' onMouseEnter={() => handleView(2)} onMouseLeave={() => handleUnview(2)}>
                                <img id='reactImg' src={reactLogo} alt='/' />
                            </div>
                        </div>
                        <div onClick={() => navigateRoute(5)}>
                            <div className='tech-logo-container' onMouseEnter={() => handleView(5)} onMouseLeave={() => handleUnview(5)}>
                                <img id='springImg' src={springBootLogo} alt='/' />
                            </div>
                        </div>
                        <div onClick={() => navigateRoute(4)}>
                            <div className='tech-logo-container' onMouseEnter={() => handleView(4)} onMouseLeave={() => handleUnview(4)}>
                                <img id='mysqlImg' src={mysqlLogo} alt='/' />
                            </div>
                        </div>

                        <div onClick={() => navigateRoute(3)}>
                            <div className='tech-logo-container' onMouseEnter={() => handleView(3)} onMouseLeave={() => handleUnview(3)}>
                                <img id='nodeImg' src={nodejsLogo} alt='/' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='technologies-vectors'>
                    <svg className='tsvg1' width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M31.5156 47.5254L40.2967 8.71345L1.78099 -0.000564967C-3.06902 21.4364 10.2423 42.7124 31.5156 47.5254Z" fill="#E9E5D9" />
                    </svg>
                    <svg className='tsvg2' width="25" height="35" viewBox="0 0 25 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.3246 32.0608L2.06956 20.5244L13.7065 2.11013C23.0983 8.92931 25.9511 21.7806 20.3246 32.0608Z" stroke="#FF8402" strokeWidth="3" />
                    </svg>
                    <svg className='tsvg3' width="41" height="57" viewBox="0 0 41 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.5977 0L0.000218118 28.3257L28.1097 56.7048C43.9048 41.0597 44.1233 15.6746 28.5977 0Z" fill="#FF8402" />
                    </svg>
                    <svg className='tsvg4' width="35" height="48" viewBox="0 0 35 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4805 47.1445L34.1433 23.4817L10.6611 -0.000449853C-2.40854 13.0692 -2.4894 34.1747 10.4805 47.1445Z" fill="#FF8402" />
                    </svg>
                    <svg className='tsvg5' width="36" height="29" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.83428 26.6688L8.21961 1.82641L33.2747 8.26642C29.1009 21.4613 15.531 29.4193 1.83428 26.6688Z" stroke="#FF8402" strokeWidth="3" />
                    </svg>
                    <svg className='tsvg6' width="34" height="131" viewBox="0 0 34 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.5742 17.6807C33.2453 26.7853 25.5938 33.9032 16.4892 33.5742C7.3847 33.2453 0.266794 25.5938 0.595721 16.4893C0.924649 7.38474 8.57614 0.266831 17.6807 0.595759C26.7856 0.91582 33.9035 8.56731 33.5742 17.6807Z" fill="#FF8402" />
                        <path d="M33.5742 67.6475C33.2453 76.7521 25.5938 83.87 16.4892 83.541C7.3847 83.2121 0.266794 75.5606 0.595721 66.4561C0.924649 57.3515 8.57614 50.2336 17.6807 50.5626C26.7856 50.8826 33.9035 58.5341 33.5742 67.6475Z" fill="#B2AFAF" />
                        <path d="M33.5742 114.618C33.2453 123.723 25.5938 130.841 16.4892 130.512C7.3847 130.183 0.266794 122.531 0.595721 113.427C0.924649 104.322 8.57614 97.2043 17.6807 97.5333C26.7856 97.8533 33.9035 105.505 33.5742 114.618Z" fill="#272D2F" />
                    </svg>
                    <svg className='tsvg7' width="82" height="39" viewBox="0 0 82 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 5L42 33L77 5" stroke="#444647" strokeWidth="9" strokeLinecap="round" />
                    </svg>
                    <svg className='tsvg8' width="92" height="104" viewBox="0 0 92 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 104V0C57.4421 0 104 46.5579 104 104H0Z" fill="#E9E5D9" />
                    </svg>
                    <svg className='tsvg9' width="124" height="134" viewBox="0 0 124 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M133.987 46.0006V0C59.9821 -6.4697e-06 7.54556e-06 59.9982 0 133.987H45.9986C46.006 85.3922 85.3919 46.0069 133.987 46.0006Z" fill="#272D2F" />
                        <path d="M40 134H128V46C79.3951 46 40 85.3951 40 134Z" fill="#FF8402" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Technologies
