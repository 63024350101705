import React, { useEffect } from 'react';
import "./stylePageNotFound.css";
function PageNotFoundDay() {
  useEffect(()=>{
    document.getElementById("navbar").style.color = "black";
    document.getElementById("logoImg").src = "/images/logo.png";
    document.querySelector(".mobile-nav-toggle").classList.remove("whiteToggle");
    document.getElementById("menu-toggle-container").style.display = "none";
    document.querySelector(".mobile-nav-toggle").classList.add("active");
    document.querySelector(".item-list").classList.add("active");
    document.getElementById("container-list1").style.color="black";
    document.getElementById("container-list2").style.color="black";
    document.getElementById("container-list3").style.color="black";
  })
  return (
    <div className='page-not-found daypnp'>
      <div className='page-not-found-container'>
        <div className='pnp-containt-container dayContainer'>
            <h1 className='dayHeading'>Page Not Found</h1>
            <button onClick={()=>window.history.back()}>Go Back</button>
        </div>
      </div>
    </div>
  )
}

export default PageNotFoundDay
