import React, { useEffect } from 'react'
import '../Service/ServiceWebxrClick.css'
function ServiceWebxrClick() {
  useEffect(() => {
    document.getElementById("navbar").style.color = "white";
    document.getElementById("logoImg").src = "/images/logoWhite.png";
    document.querySelector(".mobile-nav-toggle").classList.add("whiteToggle");
    document.getElementById("menu-toggle-container").style.display = "none";
    document.querySelector(".mobile-nav-toggle").classList.add("active");
    document.querySelector(".item-list").classList.add("active");
    document.getElementById("container-list0").style.color = "white";
    document.getElementById("container-list1").style.color = "white";
    document.getElementById("container-list2").style.color = "white";
    document.getElementById("container-list3").style.color = "white";
  })

  return (
    <div className="Mobile-application-main-container" id='webxr-page' style={{ height: "100vh", width: "100%", overflow: "auto" }}>
      <div className="Mobile-application-firstpage-page">

        <img src="/images/Vectorcolor.png" alt="/" id="vector-color" />
        <img src="/images/Vectorempty.png" alt="/" id="Vectorempty" />
        <img src="/images/Vectorempty.png" alt="/" id="Vectoremptyrotate" />
        <img src="/images/white-Vector.png" alt="/" id="white-Vector" />
        <img src="/images/threeDot.png" alt="/" id="service-threeDot" />


        <div className="Mobile-application-first-page-content-container Webxr-first-page-content-container">
          <div className="left-container-Mobile-image">
            <img src="/images/Webxr-first-page-image.png" alt="" id="Webxr-first-page-image" />
          </div>

          <div className="right-container-Mobile-application">
            <div className="right-container-Mobile-application-heading">
              Webxr
            </div>
            <div className="right-container-Mobile-application-para">
              <p>
                Provides access to input and output capabilities commonly associated with Virtual Reality (VR) and Augmented Reality (AR) devices. It allows you develop and host VR and AR experiences on the web.
              </p>
            </div>
            <div className="cards">
              <div className="card-content">
                <h1>+9</h1>
                <h3>OUR CREATION</h3>
              </div>
              <div className="card-content">
                <h1 id="card-content-h1">+19</h1>
                <h3>OUR CONNECTION</h3>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default ServiceWebxrClick